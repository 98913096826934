import React, { useEffect, useState } from 'react';
import LinkService from '../system/LinkService';
import CalenderPrizeTO from './CalenderPrizeTO';
import useApiCall from '../system/useApiCall';
import StandardTable from '../common/Component/StandardTable';
import CalenderPrizeDialog from './CalenderPrizeDialog';
import { GridColumns, GridRowsProp } from '@mui/x-data-grid';

export default function CalenderPrizePage() {
  const [editRow, setEditRow] = useState<number | null>(null);
  const [openDataDialog, setOpenDataDialog] = useState<boolean>(false);
  const [saveCount, setSaveCount] = useState<number>(0);
  const [calenderPrizeList, setCalenderPrizeList] = useState<CalenderPrizeTO[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const apiCall = useApiCall();

  useEffect(() => {
    getCalenderPrizeAll();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [saveCount]);

  useEffect(() => {
    if (editRow === null && openDataDialog) {
      setOpenDataDialog(false);
    } else if (editRow !== null && !openDataDialog) {
      setOpenDataDialog(true);
    }
  }, [editRow, openDataDialog]);

  const getCalenderPrizeAll = () => {
    setIsLoading(true);
    apiCall.get(LinkService.calenderPrizeAll()).then((data) => {
      if (data) {
        const list: CalenderPrizeTO[] = data;
        setCalenderPrizeList(list);
        setIsLoading(false);
      }
    });
  };

  const deleteCalenderPrize = (id: number) => {
    apiCall.delete(LinkService.calenderPrizeDelete(id), 'Datensatz gelöscht.').then((data) => {
      getCalenderPrizeAll();
    });
  };

  const getEditData = () => {
    const calenderPrizeData = calenderPrizeList.find((item: CalenderPrizeTO) => item.id === editRow);
    return calenderPrizeData;
  };

  const columns: GridColumns = [
    { field: 'id', headerName: 'ID', type: 'number', width: 40, editable: false },
    { field: 'description', headerName: 'Name', flex: 1, editable: true },
    {
      field: 'communityPrize',
      headerName: 'Commhuhnity Preis',
      type: 'boolean',
      flex: 1,
      editable: true,
    },
  ];

  const rows: GridRowsProp = [...calenderPrizeList];

  return (
    <div>
      <CalenderPrizeDialog
        openDialog={openDataDialog}
        setEditRow={setEditRow}
        editData={editRow ? getEditData() : undefined}
        saveCount={saveCount}
        setSaveCount={setSaveCount}
      />
      <StandardTable
        saveURL={LinkService.calenderPrizePost()}
        editRowNumber={setEditRow}
        columns={columns}
        rows={rows}
        deleteFunction={deleteCalenderPrize}
        isLoading={isLoading}
      />
    </div>
  );
}
