import * as React from 'react';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import Box from '@mui/material/Box';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import LinkService from '../system/LinkService';
import useApiCall from '../system/useApiCall';
import { useAuthUser } from 'react-auth-kit';

const theme = createTheme();

export default function ChangePasswordPage() {
  const apiCall = useApiCall();
  const authUser = useAuthUser();
  const [formData, setFormData] = React.useState({
    username: authUser()?.username || '',
    oldPassword: '',
    newPassword: '',
    newPasswordConfirm: '',
  });

  const handlePasswordChange = (event: any) => {
    apiCall.post(LinkService.authChangePassword(), formData, 'Speichern erfolgreich.').then((data) => {});
  };

  const onEnter = (event: any) => {
    if (event.keyCode === 13) {
      handlePasswordChange(event);
    }
  };

  return (
    <ThemeProvider theme={theme}>
      <Container component='main' maxWidth='xs'>
        <CssBaseline />
        <Box
          sx={{
            marginTop: 8,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>
            <LockOutlinedIcon />
          </Avatar>
          <Typography component='h1' variant='h5'>
            Passwort ändern
          </Typography>
          <Box component='div' sx={{ mt: 1 }}>
            <TextField
              margin='normal'
              required
              fullWidth
              id='oldPassword'
              label='Altes Passwort'
              name='oldPassword'
              type='password'
              autoFocus
              onChange={(e) => setFormData({ ...formData, oldPassword: e.target.value })}
            />
            <TextField
              margin='normal'
              required
              fullWidth
              name='newPassword'
              label='Neues Password'
              type='password'
              id='newPassword'
              onChange={(e) => setFormData({ ...formData, newPassword: e.target.value })}
            />
            <TextField
              margin='normal'
              required
              fullWidth
              name='newPasswordConfirm'
              label='Neues Password wiederholen'
              type='password'
              id='newPasswordConfirm'
              onChange={(e) => setFormData({ ...formData, newPasswordConfirm: e.target.value })}
              onKeyDown={(e) => onEnter(e)}
            />
            <Button fullWidth variant='contained' sx={{ mt: 3, mb: 2 }} onClick={handlePasswordChange}>
              Passwort ändern
            </Button>
          </Box>
        </Box>
      </Container>
    </ThemeProvider>
  );
}
