import { useAuthUser } from 'react-auth-kit';
import { AuthStateUserObject } from 'react-auth-kit/dist/types';

export function useAuthRoleCheck() {
  const auth = useAuthUser();

  const getRoles = (): string[] => {
    let roles: string[] = [];
    const authData: AuthStateUserObject | null = auth();
    if (authData?.roles) {
      roles = authData.roles;
    }

    return roles;
  };

  const checkHasAnyRole = (...roles: string[]): boolean => {
    const userRoles = getRoles();
    let hasRole = false;
    roles.forEach((role: string) => {
      if (userRoles.includes(role)) {
        hasRole = true;
      }
    });

    return hasRole;
  };

  const checkHasRoleStartsWith = (role: string): boolean => {
    const userRoles = getRoles();
    let hasRole = false;
    userRoles.forEach((userRole: string) => {
      if (userRole.startsWith(role)) {
        hasRole = true;
      }
    });

    return hasRole;
  };

  return { checkHasAnyRole, checkHasRoleStartsWith };
}
