import * as React from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import AddIcon from '@mui/icons-material/Add';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/DeleteOutlined';
import {
  GridRowsProp,
  GridColumns,
  GridActionsCellItem,
  DataGrid,
  GridToolbarContainer,
  GridRowModel,
  GridToolbarQuickFilter,
} from '@mui/x-data-grid';
import { useCallback, useEffect, useRef, useState } from 'react';
import { Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, LinearProgress } from '@mui/material';
import useApiCall from '../../system/useApiCall';

interface IStandardTableProps {
  editRowNumber?: React.Dispatch<React.SetStateAction<number | null>>;
  columns: GridColumns;
  rows: GridRowsProp;
  deleteFunction?: (id: number) => void;
  updateDataSource?: (data: GridRowModel) => void;
  hideToolbar?: boolean;
  hideActions?: boolean;
  isLoading: boolean;
  saveURL?: string;
  extraButtonFunction?: () => void;
  extraButtonText?: string;
}

export default function StandardTable(props: IStandardTableProps) {
  const [currentDeleteRow, setCurrentDeleteRow] = useState<number | null>(null);
  const [deleteDialogOpen, setDeleteDialogOpen] = useState<boolean>(false);
  const [tableSize, setTableSize] = useState<number>(500);
  const tableBoxRef = useRef<any>();
  const apiCall = useApiCall();

  useEffect(() => {
    const innerHeight = window.innerHeight;
    const offsetTop = tableBoxRef?.current?.offsetTop || 0;
    // get paddingTop of parent
    let paddingTop = 0;
    if (tableBoxRef?.current?.parentNode?.parentNode) {
      const nodeStyle = window.getComputedStyle(tableBoxRef.current.parentNode.parentNode);
      const paddingTopString = nodeStyle.paddingTop; // e.g. '24px'
      // remove px and convert to number
      paddingTop = parseInt(paddingTopString.substring(0, paddingTopString.length - 2));
    }
    setTableSize(innerHeight - offsetTop - paddingTop);
  }, []);

  const saveRow = useCallback(
    async (newRow: GridRowModel, oldRow: GridRowModel) => {
      if (!props.saveURL) {
        return;
      }
      await apiCall.post(props.saveURL, newRow, 'Speichern erfolgreich.');
      // update data in parent component
      if (props.updateDataSource) {
        props.updateDataSource(newRow);
      }

      return newRow;
    },
    [props.saveURL, apiCall]
  );

  const errorOnSaveRow = (error: Error) => {
    console.log('errorOnSaveRow', error);
  };

  const handleEditClick = (id: any) => (event: any) => {
    event.stopPropagation();
    if (props.editRowNumber) {
      props.editRowNumber(id);
    }
  };

  const handleAddClick = () => {
    if (props.editRowNumber) {
      props.editRowNumber(-1);
    }
  };

  const handleDeleteClick = (id: any) => (event: any) => {
    event.stopPropagation();
    setCurrentDeleteRow(id);
    setDeleteDialogOpen(true);
  };

  const handleDeleteDialogClose = () => {
    setCurrentDeleteRow(null);
    setDeleteDialogOpen(false);
  };

  const handleDeleteDialogConfirm = () => {
    if (props.deleteFunction && currentDeleteRow !== null) {
      props.deleteFunction(currentDeleteRow);
    }
    handleDeleteDialogClose();
  };

  const columns: GridColumns = props.hideActions
    ? [...props.columns]
    : [
        ...props.columns,
        {
          field: 'actions',
          type: 'actions',
          headerName: 'Actions',
          width: 100,
          cellClassName: 'actions',
          getActions: ({ id }) => {
            return [
              <GridActionsCellItem
                icon={<EditIcon />}
                label='Edit'
                className='textPrimary'
                onClick={handleEditClick(id)}
                color='inherit'
              />,
              <GridActionsCellItem
                icon={<DeleteIcon />}
                label='Delete'
                onClick={handleDeleteClick(id)}
                color='inherit'
              />,
            ];
          },
        },
      ];

  function EditToolbar() {
    return (
      <GridToolbarContainer>
        <div style={{ width: '100%' }}>
          <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            <div>
              {!props.hideToolbar && (
                <Button color='primary' startIcon={<AddIcon />} onClick={handleAddClick}>
                  Eintrag hinzufügen
                </Button>
              )}
              {props.extraButtonFunction && (
                <Button color='secondary' onClick={props.extraButtonFunction}>
                  {props.extraButtonText}
                </Button>
              )}
            </div>
            <GridToolbarQuickFilter style={{}} />
          </div>
          <LinearProgress
            variant={props.isLoading ? 'indeterminate' : 'determinate'}
            value={props.isLoading ? undefined : 0}
            style={{ height: 2 }}
          />
        </div>
      </GridToolbarContainer>
    );
  }

  return (
    <Box
      ref={tableBoxRef}
      sx={{
        height: tableSize,
        width: '100%',
        '& .actions': {
          color: 'text.secondary',
        },
        '& .textPrimary': {
          color: 'text.primary',
        },
      }}
    >
      <DataGrid
        processRowUpdate={props.saveURL ? saveRow : undefined}
        onProcessRowUpdateError={errorOnSaveRow}
        rows={props.rows}
        columns={columns}
        disableColumnFilter
        disableColumnSelector
        disableDensitySelector
        components={{
          Toolbar: EditToolbar,
        }}
        componentsProps={{
          toolbar: {
            showQuickFilter: true,
            quickFilterProps: { debounceMs: 500 },
          },
        }}
        disableSelectionOnClick
        experimentalFeatures={{ newEditingApi: true }}
      />

      <Dialog open={deleteDialogOpen}>
        <DialogTitle>Löschen</DialogTitle>
        <DialogContent>
          <DialogContentText>Möchtest du den Datensatz löschen?</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleDeleteDialogClose}>Nein</Button>
          <Button onClick={handleDeleteDialogConfirm}>Ja</Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
}
