import * as React from 'react';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import useApiCall from '../../system/useApiCall';
import LinkService from '../../system/LinkService';
import { useState } from 'react';
import WuParticipantModTO from '../TO/WuParticipantModTO';
import { Checkbox, FormControl, FormControlLabel, FormGroup, InputLabel, MenuItem, Select } from '@mui/material';
import DropdownTO from '../../common/TO/DropdownTO';

interface WuParticipantDataDialogProps {
  editData?: WuParticipantModTO;
  openDialog: boolean;
  setEditRow: React.Dispatch<React.SetStateAction<number | null>>;
  saveCount: number;
  setSaveCount: React.Dispatch<React.SetStateAction<number>>;
  wuDropdown: DropdownTO[];
  preselectedWuEvent: number;
}

export default function WuParticipantDataDialog(props: WuParticipantDataDialogProps) {
  const apiCall = useApiCall();
  const wuEventDataInit: WuParticipantModTO = {
    id: -1,
    twitchname: '',
    galleryname: '',
    simCreatedBy: '',
    typeOfRegistration: '',
    household: '',
    nameOfSim: '',
    team: '',
    comment: '',
    wildcard: false,
    wuEvent: props.preselectedWuEvent ?? -1,
    spawned: false,
    contacted: false,
    causeOfDeath: null,
    dateOfDeath: null,
  };
  const [wuParticipantData, setWuParticipantData] = useState<WuParticipantModTO>(
    props.editData ? props.editData : wuEventDataInit
  );

  React.useEffect(() => {
    if (props.preselectedWuEvent !== -1) {
      setWuParticipantData({ ...wuParticipantData, wuEvent: props.preselectedWuEvent });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.preselectedWuEvent]);

  React.useEffect(() => {
    if (props.editData) {
      setWuParticipantData(props.editData);
    }
  }, [props.editData]);

  const handleCancel = () => {
    handleClose();
  };

  const handleSave = () => {
    apiCall.post(LinkService.wuParticipantPost(), wuParticipantData, 'Speichern erfolgreich.').then((data) => {
      handleClose();
      props.setSaveCount(props.saveCount + 1);
    });
  };

  const handleClose = () => {
    setWuParticipantData(wuEventDataInit);
    props.setEditRow(null);
  };

  const title = wuParticipantData.id === -1 ? 'Neues Wer Überlebt Event hinzufügen' : 'Wer Überlebt Event bearbeiten';

  return (
    <Dialog open={props.openDialog}>
      <DialogTitle>{title}</DialogTitle>
      <DialogContent>
        <TextField
          margin='dense'
          id='twitchname'
          label='Twitch-Name'
          fullWidth
          variant='standard'
          value={wuParticipantData.twitchname}
          onChange={(e) => setWuParticipantData({ ...wuParticipantData, twitchname: e.target.value })}
        />{' '}
        <TextField
          margin='dense'
          id='galleryname'
          label='Galerie-Name'
          fullWidth
          variant='standard'
          value={wuParticipantData.galleryname}
          onChange={(e) => setWuParticipantData({ ...wuParticipantData, galleryname: e.target.value })}
        />{' '}
        <TextField
          margin='dense'
          id='simCreatedBy'
          label='Sim-Ersteller'
          fullWidth
          variant='standard'
          value={wuParticipantData.simCreatedBy}
          onChange={(e) => setWuParticipantData({ ...wuParticipantData, simCreatedBy: e.target.value })}
        />{' '}
        <TextField
          margin='dense'
          id='typeOfRegistration'
          label='Art der Reg.'
          fullWidth
          variant='standard'
          value={wuParticipantData.typeOfRegistration}
          onChange={(e) => setWuParticipantData({ ...wuParticipantData, typeOfRegistration: e.target.value })}
        />{' '}
        <TextField
          margin='dense'
          id='household'
          label='Haushalt'
          fullWidth
          variant='standard'
          value={wuParticipantData.household}
          onChange={(e) => setWuParticipantData({ ...wuParticipantData, household: e.target.value })}
        />{' '}
        <TextField
          margin='dense'
          id='nameOfSim'
          label='Sim-Name'
          fullWidth
          variant='standard'
          value={wuParticipantData.nameOfSim}
          onChange={(e) => setWuParticipantData({ ...wuParticipantData, nameOfSim: e.target.value })}
        />{' '}
        <TextField
          margin='dense'
          id='team'
          label='Team'
          fullWidth
          variant='standard'
          value={wuParticipantData.team}
          onChange={(e) => setWuParticipantData({ ...wuParticipantData, team: e.target.value })}
        />{' '}
        <TextField
          margin='dense'
          id='comment'
          label='Kommentar'
          fullWidth
          variant='standard'
          value={wuParticipantData.comment}
          onChange={(e) => setWuParticipantData({ ...wuParticipantData, comment: e.target.value })}
        />{' '}
        <FormGroup>
          <FormControlLabel
            control={
              <Checkbox
                checked={wuParticipantData.wildcard}
                onChange={(e) => setWuParticipantData({ ...wuParticipantData, wildcard: e.target.checked })}
              />
            }
            label='Wildcard'
          />
        </FormGroup>
        <FormControl fullWidth>
          <InputLabel id='wuevent-label'>WÜ Event</InputLabel>
          <Select
            required
            margin='dense'
            id='wuevent'
            label='WÜ Event'
            value={wuParticipantData.wuEvent !== -1 ? wuParticipantData.wuEvent : ''}
            onChange={(e) => {
              setWuParticipantData({
                ...wuParticipantData,
                wuEvent: e.target.value as number,
              });
            }}
          >
            {props.wuDropdown.map((dropdownItem: DropdownTO) => (
              <MenuItem key={'wuDropdown_' + dropdownItem.id} value={dropdownItem.id}>
                {dropdownItem.name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleCancel}>Abbrechen</Button>
        <Button onClick={handleSave}>Speichern</Button>
      </DialogActions>
    </Dialog>
  );
}
