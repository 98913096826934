import React from 'react';
import './App.css';
import Header from './common/Header';
import NavigationMenu from './common/NavigationMenu';

import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import CssBaseline from '@mui/material/CssBaseline';

import Drawer from '@mui/material/Drawer';
import { Toolbar, Typography } from '@mui/material';
import AppRoutes from './common/AppRoutes';
import { useAuthHeader } from 'react-auth-kit';
import axios from 'axios';
import packageJson from '../package.json';

const drawerWidth = 240;

function App() {
  const [pageName, setPageName] = React.useState<string>('xicanmeow');
  const [mobileOpen, setMobileOpen] = React.useState(false);
  const authHeader = useAuthHeader();
  axios.defaults.baseURL = (process.env.REACT_APP_BASEURL || 'http://localhost:9090') + '/api';
  axios.defaults.headers.common['Authorization'] = authHeader();

  const showOnlyPage = window.location.href.includes('sottsurvey') ? false : true;

  const handleNavbarToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const container = window !== undefined ? () => window.document.body : undefined;

  return (
    <Box sx={{ display: 'flex' }}>
      <CssBaseline />
      {showOnlyPage && (
        <AppBar
          position='fixed'
          sx={{
            width: { sm: `calc(100% - ${drawerWidth}px)` },
            ml: { sm: `${drawerWidth}px` },
          }}
        >
          <Header pageName={pageName} handleNavbarToggle={handleNavbarToggle} />
        </AppBar>
      )}
      {showOnlyPage && (
        <Box component='nav' sx={{ width: { sm: drawerWidth }, flexShrink: { sm: 0 } }} aria-label='mailbox folders'>
          {/* The implementation can be swapped with js to avoid SEO duplication of links. */}
          <Drawer
            container={container}
            variant='temporary'
            open={mobileOpen}
            onClose={handleNavbarToggle}
            ModalProps={{
              keepMounted: true, // Better open performance on mobile.
            }}
            sx={{
              display: { xs: 'block', sm: 'none' },
              '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth },
            }}
          >
            <NavigationMenu setPageName={setPageName} />
          </Drawer>
          <Drawer
            variant='permanent'
            sx={{
              display: { xs: 'none', sm: 'block' },
              '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth },
            }}
            open
          >
            <NavigationMenu setPageName={setPageName} />
          </Drawer>
        </Box>
      )}
      <Box
        component='main'
        sx={{ flexGrow: 1, p: 3, width: { sm: `calc(100% - ${showOnlyPage ? 0 : drawerWidth}px)` } }}
      >
        {showOnlyPage && <Toolbar />}
        <AppRoutes />
      </Box>
      <Box
        component='footer'
        sx={{
          flexGrow: 1,
          bottom: 0,
          position: 'fixed',
          width: { sm: `calc(100% - ${showOnlyPage ? 0 : drawerWidth}px)` },
          textAlign: 'right',
          paddingRight: '24px',
          background: '#FFFFFF',
          borderTop: '1px solid rgba(0, 0, 0, 0.12)',
        }}
      >
        <Typography sx={{ fontSize: '0.5em' }}>v{packageJson.version}</Typography>
      </Box>
    </Box>
  );
}
export default App;
