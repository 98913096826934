import React from 'react';
import { RequireAuth } from 'react-auth-kit';
import { Route, Routes } from 'react-router-dom';
import SignIn from '../pages/public/SignIn';
import GreetingPage from '../Greeting/GreetingPage';
import UserPage from '../User/UserPage';
import ChangePasswordPage from '../User/ChangePasswordPage';
import WuEventPage from '../WuEvent/ManageWuEvent/WuEventPage';
import WuParticipantPage from '../WuEvent/ManageParticipants/WuParticipantPage';
import WuEventLivePage from '../WuEvent/ManageWuEvent/WuEventLivePage';
import WuCauseOfDeathPage from '../WuEvent/ManageWuEvent/WuCauseOfDeathPage';
import WuParticipantRegistrationPage from '../WuEvent/ManageParticipants/WuParticipantRegistrationPage';
import WuEventDeathListPage from '../WuEvent/ManageWuEvent/WuEventDeathListPage';
import WuBingoListPage from '../WuEvent/ManageBingo/WuBingoListPage';
import SottEventPage from '../SottEvent/ManageSottEvent/SottEventPage';
import SottTeamPage from '../SottEvent/ManageSottTeams/SottTeamPage';
import SottGamePage from '../SottEvent/ManageGames/SottTeamPage';
import SottScorePage from '../SottEvent/ManageScore/SottScorePage';
import SottSurveyPage from '../SottEvent/ManageSurveys/SottSurveyPage';
import SottSurveyShowPage from '../SottEvent/ManageSurveys/SottSurveyShowPage';
import { ERole } from '../model/ERole';
import { useAuthRoleCheck } from './Helper/CommonHelper';
import BotCommandPage from '../BotCommand/BotCommandPage';
import GameControlPage from '../GameBasementDweller/GameControlPage';
import SupportDefinitionPage from '../SupportDefinition/SupportDefinitionPage';
import GameControlXmas23Page from '../GameXmas23/GameControlXmas23Page';
import CalenderPrizePage from '../GameXmas23/CalenderPrizePage';
import GameControlEaster24Page from '../GameEaster24/GameControlEaster24Page';
import AvatarPage from '../GameAvatar/AvatarPage';
import AvatarControlPage from '../GameAvatar/AvatarControlPage';

export default function AppRoutes() {
  const { checkHasAnyRole, checkHasRoleStartsWith } = useAuthRoleCheck();

  return (
    <Routes>
      <Route path='/' element={<WuParticipantRegistrationPage />} />
      <Route path='signin' element={<SignIn />} />
      <Route path='wueventregistration' element={<WuParticipantRegistrationPage />} />
      <Route path='wueventdeathlist' element={<WuEventDeathListPage />} />
      <Route path='sottsurvey' element={<SottSurveyPage />} />
      {checkHasAnyRole(ERole.ROLE_ADMIN) && (
        <>
          <Route
            path={'user'}
            element={
              <RequireAuth loginPath={'signin'}>
                <UserPage />
              </RequireAuth>
            }
          ></Route>
        </>
      )}
      <Route
        path={'changepassword'}
        element={
          <RequireAuth loginPath={'signin'}>
            <ChangePasswordPage />
          </RequireAuth>
        }
      ></Route>
      {checkHasRoleStartsWith(ERole.ROLE_XICANMEOW) && (
        <>
          {checkHasAnyRole(ERole.ROLE_XICANMEOW, ERole.ROLE_MODERATOR) && (
            <Route
              path={'botcommand'}
              element={
                <RequireAuth loginPath={'signin'}>
                  <BotCommandPage />
                </RequireAuth>
              }
            ></Route>
          )}
          {checkHasAnyRole(ERole.ROLE_XICANMEOW, ERole.ROLE_MODERATOR) && (
            <Route
              path={'avatar'}
              element={
                <RequireAuth loginPath={'signin'}>
                  <AvatarPage />
                </RequireAuth>
              }
            ></Route>
          )}
          {checkHasAnyRole(ERole.ROLE_XICANMEOW, ERole.ROLE_MODERATOR) && (
            <Route
              path={'avatarcontrol'}
              element={
                <RequireAuth loginPath={'signin'}>
                  <AvatarControlPage />
                </RequireAuth>
              }
            ></Route>
          )}
          {checkHasAnyRole(ERole.ROLE_XICANMEOW, ERole.ROLE_MODERATOR) && (
            <Route
              path={'supportdefinition'}
              element={
                <RequireAuth loginPath={'signin'}>
                  <SupportDefinitionPage />
                </RequireAuth>
              }
            ></Route>
          )}
          {checkHasAnyRole(ERole.ROLE_XICANMEOW, ERole.ROLE_MODERATOR) && (
            <Route
              path={'gamebdcontrol'}
              element={
                <RequireAuth loginPath={'signin'}>
                  <GameControlPage />
                </RequireAuth>
              }
            ></Route>
          )}
          {checkHasAnyRole(ERole.ROLE_XICANMEOW, ERole.ROLE_MODERATOR) && (
            <Route
              path={'gamexmas23control'}
              element={
                <RequireAuth loginPath={'signin'}>
                  <GameControlXmas23Page />
                </RequireAuth>
              }
            ></Route>
          )}
          {checkHasAnyRole(ERole.ROLE_XICANMEOW, ERole.ROLE_MODERATOR) && (
            <Route
              path={'gamexmas23calenderprize'}
              element={
                <RequireAuth loginPath={'signin'}>
                  <CalenderPrizePage />
                </RequireAuth>
              }
            ></Route>
          )}
          {checkHasAnyRole(ERole.ROLE_XICANMEOW, ERole.ROLE_MODERATOR) && (
            <Route
              path={'gameeaster24control'}
              element={
                <RequireAuth loginPath={'signin'}>
                  <GameControlEaster24Page />
                </RequireAuth>
              }
            ></Route>
          )}
          {checkHasAnyRole(ERole.ROLE_XICANMEOW, ERole.ROLE_XICANMEOW_WU_EVENT) && (
            <Route
              path={'wuevent'}
              element={
                <RequireAuth loginPath={'signin'}>
                  <WuEventPage />
                </RequireAuth>
              }
            ></Route>
          )}
          {checkHasAnyRole(ERole.ROLE_XICANMEOW, ERole.ROLE_XICANMEOW_WU_PARTICIPANT) && (
            <Route
              path={'wuparticipant'}
              element={
                <RequireAuth loginPath={'signin'}>
                  <WuParticipantPage />
                </RequireAuth>
              }
            ></Route>
          )}
          {checkHasAnyRole(ERole.ROLE_XICANMEOW, ERole.ROLE_XICANMEOW_WU_EVENTLIVE) && (
            <Route
              path={'wueventlive'}
              element={
                <RequireAuth loginPath={'signin'}>
                  <WuEventLivePage />
                </RequireAuth>
              }
            ></Route>
          )}
          {checkHasAnyRole(ERole.ROLE_XICANMEOW, ERole.ROLE_XICANMEOW_WU_BINGOLIST) && (
            <Route
              path={'wubingolist'}
              element={
                <RequireAuth loginPath={'signin'}>
                  <WuBingoListPage />
                </RequireAuth>
              }
            ></Route>
          )}
          {checkHasAnyRole(ERole.ROLE_XICANMEOW, ERole.ROLE_XICANMEOW_WU_CAUSEOFDEATH) && (
            <Route
              path={'wucauseofdeath'}
              element={
                <RequireAuth loginPath={'signin'}>
                  <WuCauseOfDeathPage />
                </RequireAuth>
              }
            ></Route>
          )}
          {checkHasAnyRole(ERole.ROLE_XICANMEOW, ERole.ROLE_XICANMEOW_GREETING) && (
            <Route
              path={'greeting'}
              element={
                <RequireAuth loginPath={'signin'}>
                  <GreetingPage />
                </RequireAuth>
              }
            ></Route>
          )}
        </>
      )}
      {checkHasAnyRole(ERole.ROLE_SOTT) && (
        <>
          <Route
            path={'sottevent'}
            element={
              <RequireAuth loginPath={'signin'}>
                <SottEventPage />
              </RequireAuth>
            }
          ></Route>
          <Route
            path={'sottteam'}
            element={
              <RequireAuth loginPath={'signin'}>
                <SottTeamPage />
              </RequireAuth>
            }
          ></Route>
          <Route
            path={'sottgame'}
            element={
              <RequireAuth loginPath={'signin'}>
                <SottGamePage />
              </RequireAuth>
            }
          ></Route>
          <Route
            path={'sottlive'}
            element={
              <RequireAuth loginPath={'signin'}>
                <SottScorePage />
              </RequireAuth>
            }
          ></Route>
          <Route
            path={'sottsurveyshow'}
            element={
              <RequireAuth loginPath={'signin'}>
                <SottSurveyShowPage />
              </RequireAuth>
            }
          ></Route>
        </>
      )}
    </Routes>
  );
}
