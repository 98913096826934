import * as React from 'react';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import useApiCall from '../../system/useApiCall';
import LinkService from '../../system/LinkService';
import SottEventTO from '../TO/SottEventTO';
import { useState } from 'react';
import DateAndTimePicker from '../../common/Component/DateAndTimePicker';

interface SottEventDataDialogProps {
  editData?: SottEventTO;
  openDialog: boolean;
  setEditRow: React.Dispatch<React.SetStateAction<number | null>>;
  saveCount: number;
  setSaveCount: React.Dispatch<React.SetStateAction<number>>;
}

export default function SottEventDataDialog(props: SottEventDataDialogProps) {
  const apiCall = useApiCall();
  const sottEventDataInit: SottEventTO = {
    id: -1,
    name: '',
    eventDate: null,
    active: false,
  };
  const [sottEventData, setSottEventData] = useState<SottEventTO>(props.editData ? props.editData : sottEventDataInit);

  React.useEffect(() => {
    if (props.editData) {
      setSottEventData(props.editData);
    }
  }, [props.editData]);

  const handleCancel = () => {
    handleClose();
  };

  const handleSave = () => {
    apiCall.post(LinkService.sottEventPost(), sottEventData, 'Speichern erfolgreich.').then((data) => {
      handleClose();
      props.setSaveCount(props.saveCount + 1);
    });
  };

  const handleClose = () => {
    setSottEventData(sottEventDataInit);
    props.setEditRow(null);
  };

  const title =
    sottEventData.id === -1
      ? 'Neues Survival of the Twitchest Event hinzufügen'
      : 'Survival of the Twitchest Event bearbeiten';

  return (
    <Dialog open={props.openDialog}>
      <DialogTitle>{title}</DialogTitle>
      <DialogContent>
        <TextField
          margin='dense'
          id='name'
          label='Name'
          fullWidth
          variant='standard'
          value={sottEventData.name}
          onChange={(e) => setSottEventData({ ...sottEventData, name: e.target.value })}
        />{' '}
        <DateAndTimePicker
          label='Event-Datum'
          value={sottEventData.eventDate}
          onChange={(e: Date | null) => setSottEventData({ ...sottEventData, eventDate: e })}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={handleCancel}>Abbrechen</Button>
        <Button onClick={handleSave}>Speichern</Button>
      </DialogActions>
    </Dialog>
  );
}
