import * as React from 'react';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import WuParticipantUserTO from '../TO/WuParticipantUserTO';
import useApiCall from '../../system/useApiCall';
import LinkService from '../../system/LinkService';
import WuEventUserTO from '../TO/WuEventUserTO';
import { useEffect, useRef, useState } from 'react';
import Util from '../../system/Util';
import WuParticipantRegistrationResultDialog from './WuParticipantRegistrationResultDialog';
import WuParticipantRegistrationResultTO from '../TO/WuParticipantRegistrationResultTO';

const theme = createTheme();

export default function WuParticipantRegistrationPage() {
  const apiCall = useApiCall();
  const wuParticipantDataInit: WuParticipantUserTO = {
    twitchname: '',
    galleryname: '',
    simCreatedBy: '',
    nameOfSim: '',
  };

  const [formData, setFormData] = useState(wuParticipantDataInit);
  const [availableTickets, setAvailableTickets] = useState(0);
  const [wuEvent, setWuEvent] = useState<WuEventUserTO>();
  const [doCheckUpdate] = useState<boolean>(true);
  const [openSuccessDialog, setOpenSuccessDialog] = useState<boolean>(false);
  const [wuRegistrationResult, setWuRegistrationResult] = useState<WuParticipantRegistrationResultTO>();
  const doCheckUpdateRef = useRef(doCheckUpdate);

  useEffect(() => {
    getWuEventInfo();
    checkForEventInfoUpdate(); // start loop for event info update
    return () => {
      doCheckUpdateRef.current = false;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // check every 10 seconds for event info
  const checkForEventInfoUpdate = () => {
    setTimeout(() => {
      if (doCheckUpdateRef.current) {
        getWuEventInfo();
        checkForEventInfoUpdate();
      }
    }, 10000);
  };

  const getWuEventInfo = () => {
    apiCall.get(LinkService.wuEventRegisterInfo()).then((data: WuEventUserTO) => {
      if (data) {
        setWuEvent(data);
        setAvailableTickets(data.maxRegistrations - data.currentRegistrations);
      }
    });
  };

  const handleRegister = () => {
    apiCall
      .post(LinkService.wuParticipantsRegister(), formData, 'Anmeldung erfolgreich.')
      .then((data: WuParticipantRegistrationResultTO) => {
        if (data?.registrationNumber > 0) {
          setWuRegistrationResult(data);
          setOpenSuccessDialog(true);
        }
      })
      .catch((data) => {
        console.log('dataerror', data);
      });
  };

  // Default message to show
  let registration = (
    <Typography component='h3' variant='h6'>
      {'Es ist gerade keine Anmeldung für Wer Überlebt aktiv.'}
    </Typography>
  );

  // Message if there is an active event but registration has not started yet.
  if (wuEvent && !wuEvent.registrationStarted) {
    registration = (
      <Typography component='h3' variant='h6'>
        {'Die Anmeldung für ' + wuEvent.name + ' beginnt am ' + Util.dateFormatter(wuEvent.registerStartDate) + '.'}
      </Typography>
    );
  } else if (wuEvent && (availableTickets <= 0 || wuEvent.registrationFull)) {
    // Message if registration is active but full
    registration = (
      <div>
        <Typography component='h1' variant='h5'>
          {'Anmeldung für ' + wuEvent.name}
        </Typography>
        <Typography component='h3' variant='h6'>
          {'Alle Plätze belegt. - Es ist leider keine Anmeldung mehr möglich.'}
        </Typography>
      </div>
    );
  } else if (wuEvent && wuEvent.registrationStopped) {
    // Message if registration is closed
    registration = (
      <div>
        <Typography component='h1' variant='h5'>
          {'Anmeldung für ' + wuEvent.name}
        </Typography>
        <Typography component='h3' variant='h6'>
          {'Die Registrierung ist geschlossen. - Es ist leider keine Anmeldung mehr möglich.'}
        </Typography>
      </div>
    );
  } else if (wuEvent) {
    // Message if registration is possible
    registration = (
      <div>
        <Typography component='h1' variant='h5'>
          {'Anmeldung für ' + wuEvent.name}
        </Typography>
        <Typography component='h3' variant='h6'>
          {'Noch ' + availableTickets + ' Plätze frei.'}
        </Typography>
        <Typography component='h3' variant='h6'>
          {'Anmeldefenster schließt sich zum ' + Util.dateFormatter(wuEvent.registerStopDate) + '.'}
        </Typography>
        <Box component='div' sx={{ mt: 1 }}>
          <TextField
            margin='normal'
            required
            fullWidth
            id='twitchname'
            label='Twitch-Name'
            helperText='Trage hier deinen Twitch-Account-Namen ein.'
            name='twitchname'
            onChange={(e) => setFormData({ ...formData, twitchname: e.target.value })}
          />
          <TextField
            margin='normal'
            required
            fullWidth
            name='galleryname'
            label='Galeriename'
            helperText='Trage hier den Namen deiner Sims 4 Galerie ein. | Du hast kein Sims 4 und somit keinen Galerienamen? Trage hier ein - (Minus) ein.'
            id='galleryname'
            onChange={(e) => setFormData({ ...formData, galleryname: e.target.value })}
          />
          <TextField
            margin='normal'
            required
            fullWidth
            name='simCreatedBy'
            label='Sim wird erstellt von'
            helperText='Wenn du deinen Sim selbst erschaffst, dann trage hier deinen Twitchnamen ein. | Du hast kein Sims 4 und kannst selbst keinen Sim erstellen? Dann trage hier "Mod" ein.'
            id='simCreatedBy'
            onChange={(e) => setFormData({ ...formData, simCreatedBy: e.target.value })}
          />
          <TextField
            margin='normal'
            required
            fullWidth
            name='nameOfSim'
            label='Name des Sim'
            helperText='Trage hier den Namen deines Sim ein. Der Name muss mindestens aus drei Zeichen bestehen.'
            id='nameOfSim'
            onChange={(e) => setFormData({ ...formData, nameOfSim: e.target.value })}
          />
          <Button fullWidth variant='contained' sx={{ mt: 3, mb: 2 }} onClick={handleRegister}>
            Anmelden
          </Button>
        </Box>
      </div>
    );
  }

  return (
    <ThemeProvider theme={theme}>
      <Container component='main' maxWidth='xs'>
        <CssBaseline />
        <Box
          sx={{
            marginTop: 8,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          {registration}
          <WuParticipantRegistrationResultDialog
            open={openSuccessDialog}
            setOpen={setOpenSuccessDialog}
            registrationResult={wuRegistrationResult}
          />
        </Box>
      </Container>
    </ThemeProvider>
  );
}
