import axios from 'axios';
import { OptionsObject, useSnackbar } from 'notistack';
import ApiErrorTO from '../model/ApiErrorTO';

export default function useApiCall() {
  const { enqueueSnackbar } = useSnackbar();

  const variantError: OptionsObject = { variant: 'error', style: { whiteSpace: 'pre-line' } };
  const variantSuccess: OptionsObject = { variant: 'success', style: { whiteSpace: 'pre-line' } };

  const errorResponseHandling = (res: any) => {
    if (res && !res.response && res instanceof Error) {
      return res.message;
    }

    const apiErrorTO: ApiErrorTO = res.response.data;
    let errorMsg = apiErrorTO.message;
    if (apiErrorTO.errors?.length > 0) {
      errorMsg += '\n';
      apiErrorTO.errors.forEach((error) => {
        errorMsg += error.message + '\n';
      });
    }
    return errorMsg;
  };

  const apiCall = {
    get: (url: string, successMsg?: string) => {
      return axios
        .get(url)
        .then((res) => {
          if (successMsg) {
            enqueueSnackbar(successMsg, variantSuccess);
          }
          return res.data;
        })
        .catch((res) => {
          enqueueSnackbar(errorResponseHandling(res), variantError);
          if (res?.response?.data) {
            throw new Error(res.response.data);
          }
          throw new Error(res);
        });
    },
    post: (url: string, payload: unknown, successMsg?: string) => {
      return axios
        .post(url, payload)
        .then((res) => {
          if (successMsg) {
            enqueueSnackbar(successMsg, variantSuccess);
          }
          return res.data;
        })
        .catch((res) => {
          enqueueSnackbar(errorResponseHandling(res), variantError);
          if (res?.response?.data) {
            throw new Error(res.response.data);
          }
          throw new Error(res);
        });
    },
    delete: (url: string, successMsg?: string) => {
      return axios
        .delete(url)
        .then((res) => {
          if (successMsg) {
            enqueueSnackbar(successMsg, variantSuccess);
          }
          return res.data;
        })
        .catch((res) => {
          enqueueSnackbar(errorResponseHandling(res), variantError);
          if (res?.response?.data) {
            throw new Error(res.response.data);
          }
          throw new Error(res);
        });
    },
  };

  return apiCall;
}
