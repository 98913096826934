// import Config from "./Config";

export default class LinkService {
  // static baseUrl = (process.env.REACT_APP_BASEURL || 'localhost:9090') + '/api';
  // static baseUrl = Config.baseurl + '/api';

  static authSignIn(): string {
    return '/auth/signin';
  }

  static authSignUp(): string {
    return '/auth/signup';
  }

  static authRefreshToken(): string {
    return '/auth/refreshtoken';
  }

  static authChangePassword(): string {
    return '/auth/changepassword';
  }

  static testAll(): string {
    return '/test/all';
  }

  static testUser(): string {
    return '/test/user';
  }

  static testMod(): string {
    return '/test/mod';
  }

  static testAdmin(): string {
    return '/test/admin';
  }

  static greetingPost(): string {
    return '/greeting';
  }

  static greetingImport(): string {
    return '/greeting/import';
  }

  static greetingDelete(id: number): string {
    return '/greeting/' + id;
  }

  static greetingAll(): string {
    return '/greeting/all';
  }

  static userAll(): string {
    return '/user/all';
  }

  static userDelete(id: number): string {
    return '/user/' + id;
  }

  static userPost(): string {
    return '/user';
  }

  static wuEventAll(): string {
    return '/wuevent/all';
  }

  static wuEventActiveId(): string {
    return '/wuevent/wueventactiveid';
  }

  static wuEventRegisterInfo(): string {
    return '/wuevent/wuregisterinfo';
  }

  static wuEventPost(): string {
    return '/wuevent';
  }

  static wuEventDelete(id: number): string {
    return '/wuevent/' + id;
  }

  static wuParticipantAll(): string {
    return '/wuevent/wuparticipant/all';
  }

  static wuParticipantsByWuEventId(wuEventId: number): string {
    return '/wuevent/wuparticipant/wuevent/' + wuEventId;
  }

  static wuParticipantPost(): string {
    return '/wuevent/wuparticipant';
  }

  static wuParticipantsRegister(): string {
    return '/wuevent/wuparticipant/register';
  }

  static wuParticipantDelete(id: number): string {
    return '/wuevent/wuparticipant/' + id;
  }

  static wuBingoByWuEventIdList(id: number): string {
    return '/wubingo/bingolist/' + id;
  }

  static wuEventDeathList(): string {
    return '/wuevent/wueventdeathinfo';
  }

  static causeOfDeathPost(): string {
    return '/wuevent/wucauseofdeath';
  }

  static causeOfDeathById(id: number): string {
    return '/wuevent/wucauseofdeath/' + id;
  }

  static causeOfDeathDelete(id: number): string {
    return '/wuevent/wucauseofdeath/' + id;
  }

  static dropdownWuEvents(): string {
    return '/manager/dropdown/wuevents';
  }

  static dropdownCauseOfDeath(): string {
    return '/manager/dropdown/causeofdeath';
  }

  static dropdownSottEvents(): string {
    return '/manager/dropdown/sottevents';
  }

  static sottEventAll(): string {
    return '/sottevent/all';
  }

  static sottEventPost(): string {
    return '/sottevent';
  }

  static sottEventActiveId(): string {
    return '/sottevent/activeid';
  }

  static sottEventDelete(id: number): string {
    return '/sottevent/' + id;
  }

  static sottTeamBySottEventId(sottEventId: number): string {
    return '/sottteam/sottevent/' + sottEventId;
  }

  static sottTeamPost(): string {
    return '/sottteam';
  }

  static sottTeamDelete(teamId: number): string {
    return '/sottteam/' + teamId;
  }

  static sottGameBySottEventId(sottEventId: number): string {
    return '/sottgame/sottevent/' + sottEventId;
  }

  static sottGamePost(): string {
    return '/sottgame';
  }

  static sottGameDelete(teamId: number): string {
    return '/sottgame/' + teamId;
  }

  static sottCreateGameRoundScoreByEventId(sottEventId: number): string {
    return '/sottscore/createbyeventid/' + sottEventId;
  }

  static sottGameRoundScoreByEventId(sottEventId: number): string {
    return '/sottscore/byeventid/' + sottEventId;
  }

  static sottSaveScoreByRoundScoreId(scoreRoundId: number): string {
    return '/sottscore/' + scoreRoundId;
  }

  static sottSurveyPost(): string {
    return '/sottsurvey';
  }

  static sottSurveyGetAll(): string {
    return '/sottsurvey/all';
  }

  static sottSurveyGetCountOfAnswers(): string {
    return '/sottsurvey/countofanswers';
  }
  static sottSurveyGetCountOfAnswersAsCSV(): string {
    return '/sottsurvey/countofanswerscsv';
  }

  static sottSurveyGetAnswersAsCSV(): string {
    return '/sottsurvey/answerscsv';
  }

  static sottSurveyDeleteAll(): string {
    return '/sottsurvey/all';
  }

  static getBotCommandAll(): string {
    return '/command/all';
  }

  static botCommandPost(): string {
    return '/command/updatecommand';
  }

  static botCommandDelete(id: number): string {
    return '/command/' + id;
  }

  static botCommandSendToBot(): string {
    return '/command/sendToBot';
  }

  static getGameBdControlAll(): string {
    return '/game/basementdweller/control/all';
  }

  static postGameBdControl(): string {
    return '/game/basementdweller/control';
  }

  static getSupportDefinitionAll(): string {
    return '/support/definitions/all';
  }

  static postSupportDefinition(): string {
    return '/support/definition';
  }

  static getGameXmas23ControlAll(): string {
    return '/xmas23/setup/all';
  }

  static postGameXmas23Control(): string {
    return '/xmas23/setup/update';
  }

  static calenderPrizeAll(): string {
    return '/xmas23/calendarprizepool';
  }

  static calenderPrizeDelete(id: number): string {
    return '/xmas23/calendarprizepool/delete/' + id;
  }

  static calenderPrizePost(): string {
    return '/xmas23/calendarprizepool';
  }

  static getGameEaster24ControlAll(): string {
    return '/easter24/setup/all';
  }

  static postGameEaster24Control(): string {
    return '/easter24/setup/update';
  }

  static postAvatarObject(): string {
    return '/avatar/management/upload';
  }

  static getAllAvatarObjects(): string {
    return '/avatar/management/all';
  }

  static deleteAvatarObject(id: number): string {
    return '/avatar/management/' + id;
  }

  static getAvatarControlAll(): string {
    return '/avatar/setup/all';
  }

  static postAvatarControl(): string {
    return '/avatar/setup/update';
  }
}
