import * as React from 'react';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import useApiCall from '../../system/useApiCall';
import LinkService from '../../system/LinkService';
import { useState } from 'react';
import SottGameTO from '../TO/SottGameTO';
import { FormControl, InputLabel, MenuItem, Select } from '@mui/material';
import DropdownTO from '../../common/TO/DropdownTO';
import Util from '../../system/Util';

interface SottGameDataDialogProps {
  editData?: SottGameTO;
  openDialog: boolean;
  setEditRow: React.Dispatch<React.SetStateAction<number | null>>;
  saveCount: number;
  setSaveCount: React.Dispatch<React.SetStateAction<number>>;
  sottEventDropdown: DropdownTO[];
  preselectedSottEvent: number;
}

export default function SottTeamDataDialog(props: SottGameDataDialogProps) {
  const apiCall = useApiCall();
  const sottTeamDataInit: SottGameTO = {
    id: -1,
    name: '',
    sottEvent: -1,
    rounds: 1,
  };
  const [sottTeamData, setSottTeamData] = useState<SottGameTO>(props.editData ? props.editData : sottTeamDataInit);

  React.useEffect(() => {
    if (props.preselectedSottEvent !== -1) {
      setSottTeamData({ ...sottTeamData, sottEvent: props.preselectedSottEvent });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.preselectedSottEvent]);

  React.useEffect(() => {
    if (props.editData) {
      setSottTeamData(props.editData);
    }
  }, [props.editData]);

  const handleCancel = () => {
    handleClose();
  };

  const handleSave = () => {
    apiCall.post(LinkService.sottGamePost(), sottTeamData, 'Speichern erfolgreich.').then((data) => {
      handleClose();
      props.setSaveCount(props.saveCount + 1);
    });
  };

  const handleClose = () => {
    setSottTeamData(sottTeamDataInit);
    props.setEditRow(null);
  };

  const title = sottTeamData.id === -1 ? 'Neues Team hinzufügen' : 'Team bearbeiten';

  return (
    <Dialog open={props.openDialog}>
      <DialogTitle>{title}</DialogTitle>
      <DialogContent>
        <TextField
          margin='dense'
          id='name'
          label='Name'
          fullWidth
          variant='standard'
          value={sottTeamData.name}
          onChange={(e) => setSottTeamData({ ...sottTeamData, name: e.target.value })}
        />{' '}
        <TextField
          margin='dense'
          id='rounds'
          label='Runden'
          fullWidth
          variant='standard'
          type='number'
          value={sottTeamData.rounds}
          onChange={(e) =>
            setSottTeamData({
              ...sottTeamData,
              rounds: Util.isStringInt(e.target.value) ? Util.parseStringToInt(e.target.value) : 1,
            })
          }
        />{' '}
        <FormControl fullWidth>
          <InputLabel id='sottevent-label'>SotT Event</InputLabel>
          <Select
            required
            margin='dense'
            id='sottevent'
            label='SotT Event'
            value={sottTeamData.sottEvent !== -1 ? sottTeamData.sottEvent : ''}
            onChange={(e) => {
              setSottTeamData({
                ...sottTeamData,
                sottEvent: e.target.value as number,
              });
            }}
          >
            {props.sottEventDropdown.map((dropdownItem: DropdownTO) => (
              <MenuItem key={'wuDropdown_' + dropdownItem.id} value={dropdownItem.id}>
                {dropdownItem.name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleCancel}>Abbrechen</Button>
        <Button onClick={handleSave}>Speichern</Button>
      </DialogActions>
    </Dialog>
  );
}
