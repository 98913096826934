import React, { useState, FocusEvent } from 'react';
import { Box, CircularProgress, TextField } from '@mui/material';
import Util from '../../system/Util';
import useApiCall from '../../system/useApiCall';
import LinkService from '../../system/LinkService';
import SottGameRoundScoreTO from '../TO/SottGameRoundScoreTO';

interface ScoreCellInputProps {
  scoreData: SottGameRoundScoreTO;
  updateTotalScore: (data: SottGameRoundScoreTO) => void;
}

export default function ScoreCellInput(props: ScoreCellInputProps) {
  const [score, setScore] = useState<number | null>(props.scoreData.score);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isError, setIsError] = useState<boolean>(false);
  const apiCall = useApiCall();

  function handleOnBlur(e: FocusEvent<HTMLInputElement>) {
    // save scoreValue to DB by scoreRoundID
    const inputValue = e.target.value.trim();
    if (!Util.isStringInt(inputValue) && inputValue !== '') {
      setIsError(true);
      return;
    }
    setIsError(false);
    const scoreValue = Util.parseStringToInt(inputValue);
    const scoreDataRequest = { ...props.scoreData };
    scoreDataRequest.score = scoreValue;
    setIsLoading(true);
    apiCall
      .post(LinkService.sottSaveScoreByRoundScoreId(props.scoreData.id), scoreDataRequest)
      .then((data: SottGameRoundScoreTO) => {
        if (data) {
          setScore(data.score);
          props.updateTotalScore(data);
        }
        setIsLoading(false);
      });
  }

  function handleChange(e: React.ChangeEvent<HTMLInputElement>) {
    const scoreValue = Util.isStringInt(e.target.value) ? Util.parseStringToInt(e.target.value) : null;
    setScore(scoreValue);
  }

  return (
    <Box sx={{ display: 'flex', alignItems: 'center' }}>
      <Box sx={{ m: 1, position: 'relative' }}>
        <TextField
          variant='standard'
          error={isError}
          size='small'
          helperText={isError ? 'Wert ungültig (nur Zahlen erlaubt)' : ''}
          disabled={isLoading ? true : false}
          value={score}
          onBlur={handleOnBlur}
          onChange={handleChange}
        />
        {isLoading && (
          <CircularProgress
            size={24}
            sx={{
              position: 'absolute',
              top: '50%',
              left: '50%',
              marginTop: '-12px',
              marginLeft: '-12px',
            }}
          />
        )}
      </Box>
    </Box>
  );
}
