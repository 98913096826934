import React, { useEffect, useState } from 'react';
import LinkService from '../system/LinkService';
import useApiCall from '../system/useApiCall';
import StandardTable from '../common/Component/StandardTable';
import { GridColumns, GridRowsProp, GridValueGetterParams } from '@mui/x-data-grid';
import UserEditTO from './UserEditTO';
import UserDataDialog from './UserDataDialog';

export default function UserPage() {
  const [editRow, setEditRow] = useState<number | null>(null);
  const [openDataDialog, setOpenDataDialog] = useState<boolean>(false);
  const [saveCount, setSaveCount] = useState<number>(0);
  const [userList, setUserList] = useState<UserEditTO[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const apiCall = useApiCall();

  useEffect(() => {
    getUserAll();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [saveCount]);

  useEffect(() => {
    if (editRow === null && openDataDialog) {
      setOpenDataDialog(false);
    } else if (editRow !== null && !openDataDialog) {
      setOpenDataDialog(true);
    }
  }, [editRow, openDataDialog]);

  const getUserAll = () => {
    setIsLoading(true);
    apiCall.get(LinkService.userAll()).then((data) => {
      if (data) {
        const list: UserEditTO[] = data;
        setUserList(list);
        setIsLoading(false);
      }
    });
  };

  const deleteUserData = (id: number) => {
    apiCall.delete(LinkService.userDelete(id), 'Datensatz gelöscht.').then((data) => {
      getUserAll();
    });
  };

  const getEditData = () => {
    const user = userList.find((item: UserEditTO) => item.id === editRow);
    return user;
  };

  const getRolesAsString = (params: GridValueGetterParams) => {
    if (params.value) {
      return params.value.join(', ');
    }
    return '';
  };

  const columns: GridColumns = [
    { field: 'id', headerName: 'ID', type: 'number', width: 30, editable: false },
    { field: 'username', headerName: 'User', editable: true },
    { field: 'email', headerName: 'eMail', flex: 1, editable: true },
    { field: 'roles', headerName: 'Rollen', flex: 1, editable: false, valueGetter: getRolesAsString },
  ];

  const rows: GridRowsProp = [...userList];

  return (
    <div>
      <UserDataDialog
        openDialog={openDataDialog}
        setEditRow={setEditRow}
        editData={editRow ? getEditData() : undefined}
        saveCount={saveCount}
        setSaveCount={setSaveCount}
      />
      <StandardTable
        saveURL={LinkService.userPost()}
        editRowNumber={setEditRow}
        columns={columns}
        rows={rows}
        deleteFunction={deleteUserData}
        isLoading={isLoading}
      />
    </div>
  );
}
