import React, { useEffect, useState } from 'react';
import LinkService from '../system/LinkService';
import GreetingTO from './GreetingTO';
import useApiCall from '../system/useApiCall';
import StandardTable from '../common/Component/StandardTable';
import GreetingDataDialog from './GreetingDataDialog';
import { GridColumns, GridRowsProp } from '@mui/x-data-grid';

export default function GreetingPage() {
  const [editRow, setEditRow] = useState<number | null>(null);
  const [openDataDialog, setOpenDataDialog] = useState<boolean>(false);
  const [saveCount, setSaveCount] = useState<number>(0);
  const [greetingList, setGreetingList] = useState<GreetingTO[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const apiCall = useApiCall();

  useEffect(() => {
    getGreetingAll();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [saveCount]);

  useEffect(() => {
    if (editRow === null && openDataDialog) {
      setOpenDataDialog(false);
    } else if (editRow !== null && !openDataDialog) {
      setOpenDataDialog(true);
    }
  }, [editRow, openDataDialog]);

  const getGreetingAll = () => {
    setIsLoading(true);
    apiCall.get(LinkService.greetingAll()).then((data) => {
      if (data) {
        const list: GreetingTO[] = data;
        setGreetingList(list);
        setIsLoading(false);
      }
    });
  };

  const deleteGreetingData = (id: number) => {
    apiCall.delete(LinkService.greetingDelete(id), 'Datensatz gelöscht.').then((data) => {
      getGreetingAll();
    });
  };

  const getEditData = () => {
    const greetingData = greetingList.find((item: GreetingTO) => item.id === editRow);
    return greetingData;
  };

  const columns: GridColumns = [
    { field: 'id', headerName: 'ID', type: 'number', width: 40, editable: false },
    { field: 'username', headerName: 'User', flex: 1, editable: true },
    { field: 'greeting', headerName: 'Begrüßung', flex: 9, editable: true },
  ];

  const rows: GridRowsProp = [...greetingList];

  return (
    <div>
      <GreetingDataDialog
        openDialog={openDataDialog}
        setEditRow={setEditRow}
        editData={editRow ? getEditData() : undefined}
        saveCount={saveCount}
        setSaveCount={setSaveCount}
      />
      <StandardTable
        saveURL={LinkService.greetingPost()}
        editRowNumber={setEditRow}
        columns={columns}
        rows={rows}
        deleteFunction={deleteGreetingData}
        isLoading={isLoading}
      />
    </div>
  );
}
