import React, { useEffect, useState } from 'react';
import LinkService from '../system/LinkService';
import useApiCall from '../system/useApiCall';
import StandardTable from '../common/Component/StandardTable';
import { GridColumns, GridRowsProp } from '@mui/x-data-grid';
import BotCommandTO from './BotCommandTO';
import TableUtil from '../system/TableUtil';
import BotCommandDataDialog from './BotCommandDataDialog';

export default function BotCommandPage() {
  const [editRow, setEditRow] = useState<number | null>(null);
  const [openDataDialog, setOpenDataDialog] = useState<boolean>(false);
  const [saveCount, setSaveCount] = useState<number>(0);
  const [commandList, setCommandList] = useState<BotCommandTO[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const apiCall = useApiCall();

  useEffect(() => {
    getCommandAll();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [saveCount]);

  useEffect(() => {
    if (editRow === null && openDataDialog) {
      setOpenDataDialog(false);
    } else if (editRow !== null && !openDataDialog) {
      setOpenDataDialog(true);
    }
  }, [editRow, openDataDialog]);

  const getCommandAll = () => {
    setIsLoading(true);
    apiCall.get(LinkService.getBotCommandAll()).then((data) => {
      if (data) {
        const list: BotCommandTO[] = data;
        setCommandList(list);
        setIsLoading(false);
      }
    });
  };

  const deleteBotCommandData = (id: number) => {
    apiCall.delete(LinkService.botCommandDelete(id), 'Datensatz gelöscht.').then((data) => {
      getCommandAll();
    });
  };

  const getEditData = () => {
    const command = commandList.find((item: BotCommandTO) => item.id === editRow);
    return command;
  };

  const updateBotCommandList = () => {
    apiCall.get(LinkService.botCommandSendToBot(), 'An Bot gesendet.').then((data) => {});
  };

  const columns: GridColumns = [
    { field: 'id', headerName: 'ID', type: 'number', width: 30, editable: false },
    { field: 'command', headerName: 'Command', flex: 2, editable: true },
    { field: 'description', headerName: 'Beschreibung', flex: 9, editable: true },
    {
      field: 'active',
      headerName: 'aktiv',
      type: 'boolean',
      flex: 1,
      editable: true,
    },
    {
      field: 'activeModifiedOn',
      headerName: 'letzte Änderung',
      flex: 2,
      editable: false,
      valueFormatter: TableUtil.formatDateCell,
    },
  ];

  const rows: GridRowsProp = [...commandList];

  return (
    <div>
      <BotCommandDataDialog
        openDialog={openDataDialog}
        setEditRow={setEditRow}
        editData={editRow ? getEditData() : undefined}
        saveCount={saveCount}
        setSaveCount={setSaveCount}
      />
      <StandardTable
        saveURL={LinkService.botCommandPost()}
        editRowNumber={setEditRow}
        columns={columns}
        rows={rows}
        deleteFunction={deleteBotCommandData}
        isLoading={isLoading}
        extraButtonFunction={updateBotCommandList}
        extraButtonText='an Bot senden'
      />
    </div>
  );
}
