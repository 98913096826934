import * as React from 'react';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import Box from '@mui/material/Box';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { useSignIn } from 'react-auth-kit';
import axios from 'axios';
import LinkService from '../../system/LinkService';
import { useSnackbar } from 'notistack';

const theme = createTheme();

export default function SignIn() {
  const { enqueueSnackbar } = useSnackbar();
  const signIn = useSignIn();
  const [formData, setFormData] = React.useState({ username: '', password: '' });

  const handleLogin = (event: React.MouseEvent<HTMLElement>) => {
    axios
      .post(LinkService.authSignIn(), formData)
      .then((res) => {
        if (res.status === 200) {
          const user = {
            username: res.data.username,
            email: res.data.email,
            roles: res.data.roles,
            name: res.data.username,
          };
          if (
            signIn({
              token: res.data.token,
              expiresIn: res.data.expiresInMinutes,
              tokenType: res.data.type,
              authState: user,
              refreshToken: res.data.refreshToken, // Only if you are using refreshToken feature
              refreshTokenExpireIn: res.data.refreshTokenExpiresInMinutes,
            })
          ) {
            // Only if you are using refreshToken feature
            // Redirect or do-something
          } else {
            //Throw error
            enqueueSnackbar('SingIn Error', { variant: 'error' });
          }
        }
      })
      .catch((reason) => {
        enqueueSnackbar('Axios Error: ' + reason, { variant: 'error' });
      });
  };

  const onEnter = (event: any) => {
    if (event.keyCode === 13) {
      handleLogin(event);
    }
  };

  return (
    <ThemeProvider theme={theme}>
      <Container component='main' maxWidth='xs'>
        <CssBaseline />
        <Box
          sx={{
            marginTop: 8,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>
            <LockOutlinedIcon />
          </Avatar>
          <Typography component='h1' variant='h5'>
            Sign in
          </Typography>
          <Box component='div' sx={{ mt: 1 }}>
            <TextField
              margin='normal'
              required
              fullWidth
              id='username'
              label='Username'
              name='username'
              autoComplete='username'
              autoFocus
              onChange={(e) => setFormData({ ...formData, username: e.target.value })}
            />
            <TextField
              margin='normal'
              required
              fullWidth
              name='password'
              label='Password'
              type='password'
              id='password'
              autoComplete='current-password'
              onChange={(e) => setFormData({ ...formData, password: e.target.value })}
              onKeyDown={(e) => onEnter(e)}
            />
            <Button fullWidth variant='contained' sx={{ mt: 3, mb: 2 }} onClick={handleLogin}>
              Sign In
            </Button>
          </Box>
        </Box>
      </Container>
    </ThemeProvider>
  );
}
