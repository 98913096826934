import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { Typography } from '@mui/material';

interface ConfirmationDialogProps {
  title?: string;
  text?: string;
  openDialog: boolean;
  isLoading?: boolean;
  onConfirm: () => void;
  onCancle: () => void;
}

export default function ConfirmationDialog(props: ConfirmationDialogProps) {
  return (
    <Dialog open={props.openDialog}>
      <DialogTitle>{props.title}</DialogTitle>
      <DialogContent>
        <Typography>{props.text}</Typography>
      </DialogContent>
      <DialogActions>
        <Button onClick={props.onCancle}>Abbrechen</Button>
        <Button onClick={props.onConfirm} disabled={props.isLoading}>
          {props.isLoading ? 'läd...' : 'Bestätigen'}
        </Button>
      </DialogActions>
    </Dialog>
  );
}
