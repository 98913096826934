import * as React from 'react';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { useRef, useState } from 'react';
import GreetingTO from './GreetingTO';
import useApiCall from '../system/useApiCall';
import LinkService from '../system/LinkService';
import { useSnackbar } from 'notistack';

interface GreetingDataDialogProps {
  editData?: GreetingTO;
  openDialog: boolean;
  setEditRow: React.Dispatch<React.SetStateAction<number | null>>;
  saveCount: number;
  setSaveCount: React.Dispatch<React.SetStateAction<number>>;
}

export default function GreetingDataDialog(props: GreetingDataDialogProps) {
  const apiCall = useApiCall();
  const { enqueueSnackbar } = useSnackbar();
  const inputFile = useRef<any>();
  const greetingDataInit: GreetingTO = {
    id: -1,
    username: '',
    greeting: '',
  };
  const [greetingData, setGreetingData] = useState<GreetingTO>(props.editData ? props.editData : greetingDataInit);

  React.useEffect(() => {
    if (props.editData) {
      setGreetingData(props.editData);
    }
  }, [props.editData]);

  const onImportClick = () => {
    if (inputFile?.current) {
      inputFile.current.click();
    }
  };

  const parseImportData = (rawData: string) => {
    let data;

    // check if data is JSON
    try {
      data = JSON.parse(rawData);
    } catch {
      enqueueSnackbar('Datei hat ein ungültiges Format.', { variant: 'error' });
      return;
    }

    const keys = Object.keys(data);
    // check if JSON structure is okay
    // data has at least 1 key and data has attribute greeting
    if (keys?.length < 1 || data[keys[0]].greetings === undefined) {
      enqueueSnackbar('Datei hat ein ungültiges Format.', { variant: 'error' });
      return;
    }

    const greetingList: GreetingTO[] = [];
    for (const key of keys) {
      const greetings = data[key].greetings;
      for (const greeting of greetings) {
        const greetingTO = {
          id: -1,
          username: key,
          greeting: greeting,
        };
        greetingList.push(greetingTO);
      }
    }
    apiCall.post(LinkService.greetingImport(), greetingList).then((data) => {
      handleClose();
      enqueueSnackbar('Es wurden ' + data + ' Einträge hinzugefügt.', { variant: 'success' });
      props.setSaveCount(props.saveCount + 1);
    });
  };

  const onImportChange = (e: any) => {
    const fileReader = new FileReader();
    fileReader.readAsText(e.target.files[0], 'UTF8');
    fileReader.onload = (e: any) => {
      parseImportData(e.target.result);
    };
  };

  const handleCancel = () => {
    handleClose();
  };

  const handleSave = () => {
    apiCall.post(LinkService.greetingPost(), greetingData, 'Speichern erfolgreich.').then((data) => {
      handleClose();
      props.setSaveCount(props.saveCount + 1);
    });
  };

  const handleClose = () => {
    setGreetingData(greetingDataInit);
    props.setEditRow(null);
  };

  const title = greetingData.id === -1 ? 'Neue Begrüßung hinzufügen' : 'Begrüßung bearbeiten';

  return (
    <Dialog open={props.openDialog}>
      <DialogTitle>{title}</DialogTitle>
      <DialogContent>
        {/* <DialogContentText>Neue Begrüßung für User hinzufügen.</DialogContentText> */}
        <TextField
          autoFocus
          margin='dense'
          id='username'
          label='Username'
          fullWidth
          variant='standard'
          value={greetingData.username}
          onChange={(e) => setGreetingData({ ...greetingData, username: e.target.value })}
        />{' '}
        <TextField
          margin='dense'
          id='greeting'
          label='Begrüßungstext'
          fullWidth
          variant='standard'
          value={greetingData.greeting}
          onChange={(e) => setGreetingData({ ...greetingData, greeting: e.target.value })}
        />
      </DialogContent>
      <DialogActions>
        <input id='fileimput' type='file' ref={inputFile} style={{ display: 'none' }} onChange={onImportChange} />
        <Button onClick={onImportClick}>Import file</Button>
        <Button onClick={handleCancel}>Abbrechen</Button>
        <Button onClick={handleSave}>Speichern</Button>
      </DialogActions>
    </Dialog>
  );
}
