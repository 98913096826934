import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import WuParticipantRegistrationResultTO from '../TO/WuParticipantRegistrationResultTO';
import { Typography } from '@mui/material';

interface WuParticipantRegistrationResultDialogProps {
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  registrationResult?: WuParticipantRegistrationResultTO;
}

export default function WuParticipantRegistrationResultDialog(props: WuParticipantRegistrationResultDialogProps) {
  const handleClose = () => {
    props.setOpen(false);
  };
  return (
    <Dialog open={props.open}>
      <DialogTitle>{'Anmeldung erfolgreich!'}</DialogTitle>
      <DialogContent>
        <Typography variant='body2' gutterBottom>
          Hallo {props.registrationResult?.twitchname},<br />
          <br />
          deine Anmeldung mit dem Sim <i>{props.registrationResult?.nameOfSim}</i> war erfolgreich! <br /> Deine{' '}
          <b>Anmeldenummer</b> ist: {props.registrationResult?.registrationNumber}
        </Typography>
        <Typography variant='body2' gutterBottom>
          Hier sind noch einige Informationen für dich.
        </Typography>
        {props.registrationResult?.hashtag && (
          <Typography variant='body2' gutterBottom>
            <b>Hashtags:</b> {props.registrationResult.hashtag}
          </Typography>
        )}
        {props.registrationResult?.theme && (
          <Typography variant='body2' gutterBottom>
            Das <b>Thema</b> ist: {props.registrationResult.theme}
          </Typography>
        )}
        {props.registrationResult?.description && (
          <Typography variant='body2' gutterBottom paragraph>
            {props.registrationResult.description}
          </Typography>
        )}
        {props.registrationResult?.conditions && (
          <Typography variant='body2' gutterBottom paragraph>
            <b>Bedingungen:</b>
            <br />
            {props.registrationResult.conditions}
          </Typography>
        )}
        <Typography variant='body2' gutterBottom>
          <i>
            Wenn du auf SCHLIESSEN klickst, kannst du dir diesen Text nicht mehr anzeigen lassen. Speicher ihn dir
            vorher ab, falls du ihn noch einmal benötigst!
          </i>
        </Typography>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose}>Schließen</Button>
      </DialogActions>
    </Dialog>
  );
}
