import * as React from 'react';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { useState } from 'react';

import useApiCall from '../system/useApiCall';
import LinkService from '../system/LinkService';
import UserEditTO from './UserEditTO';
import { FormControl, InputLabel, MenuItem, Select } from '@mui/material';
import { ERole } from '../model/ERole';
import UserNewTO from './UserNewTO';

interface UserDataDialogProps {
  editData?: UserEditTO;
  openDialog: boolean;
  setEditRow: React.Dispatch<React.SetStateAction<number | null>>;
  saveCount: number;
  setSaveCount: React.Dispatch<React.SetStateAction<number>>;
}

export default function GreetingDataDialog(props: UserDataDialogProps) {
  const apiCall = useApiCall();
  const userNewDataInit: UserNewTO = {
    id: -1,
    username: '',
    email: '',
    password: '',
    roles: [],
  };
  const [userData, setUserData] = useState<UserEditTO | UserNewTO>(props.editData ? props.editData : userNewDataInit);

  React.useEffect(() => {
    if (props.editData) {
      setUserData(props.editData);
    }
  }, [props.editData]);

  const handleCancel = () => {
    handleClose();
  };

  const handleSave = () => {
    if (userData.id === -1) {
      apiCall.post(LinkService.authSignUp(), userData, 'Speichern erfolgreich.').then((data) => {
        handleClose();
        props.setSaveCount(props.saveCount + 1);
      });
    } else {
      apiCall.post(LinkService.userPost(), userData, 'Speichern erfolgreich.').then((data) => {
        handleClose();
        props.setSaveCount(props.saveCount + 1);
      });
    }
  };

  const handleClose = () => {
    setUserData(userNewDataInit);
    props.setEditRow(null);
  };

  const title = userData.id === -1 ? 'Neuen User hinzufügen' : 'User bearbeiten';
  return (
    <Dialog open={props.openDialog}>
      <DialogTitle>{title}</DialogTitle>
      <DialogContent>
        <TextField
          margin='dense'
          id='username'
          label='Username'
          fullWidth
          variant='standard'
          value={userData.username}
          onChange={(e) => setUserData({ ...userData, username: e.target.value })}
        />{' '}
        <TextField
          margin='dense'
          id='email'
          label='eMail'
          fullWidth
          variant='standard'
          value={userData.email}
          onChange={(e) => setUserData({ ...userData, email: e.target.value })}
        />{' '}
        {userData.id === -1 && (
          <TextField
            margin='dense'
            id='password'
            label='Passwort'
            fullWidth
            variant='standard'
            // value={userData.password}
            onChange={(e) => setUserData({ ...userData, password: e.target.value })}
          />
        )}
        <FormControl fullWidth>
          <InputLabel id='wuevent-label'>Rolle</InputLabel>
          <Select
            multiple
            margin='dense'
            id='role'
            label='Rolle'
            fullWidth
            variant='standard'
            value={userData.roles}
            onChange={(e) => setUserData({ ...userData, roles: e.target.value as ERole[] })}
          >
            <MenuItem value={ERole.ROLE_USER}>User</MenuItem>
            <MenuItem value={ERole.ROLE_MODERATOR}>Mod</MenuItem>
            <MenuItem value={ERole.ROLE_ADMIN}>Admin</MenuItem>
            <MenuItem value={ERole.ROLE_SOTT}>SotT</MenuItem>
            <MenuItem value={ERole.ROLE_XICANMEOW}>xicanmeow - alle Rechte</MenuItem>
            <MenuItem value={ERole.ROLE_XICANMEOW_LIMITED}>xicanmeow - eingeschränkte Rechte</MenuItem>
            <MenuItem value={ERole.ROLE_XICANMEOW_WU_EVENT}>WÜ Event</MenuItem>
            <MenuItem value={ERole.ROLE_XICANMEOW_WU_PARTICIPANT}>WÜ Teilnehmer</MenuItem>
            <MenuItem value={ERole.ROLE_XICANMEOW_WU_EVENTLIVE}>WÜ Event Live</MenuItem>
            <MenuItem value={ERole.ROLE_XICANMEOW_WU_BINGOLIST}>WÜ Bingos</MenuItem>
            <MenuItem value={ERole.ROLE_XICANMEOW_WU_CAUSEOFDEATH}>WÜ Todesursache</MenuItem>
            <MenuItem value={ERole.ROLE_XICANMEOW_GREETING}>Greeting</MenuItem>
          </Select>
        </FormControl>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleCancel}>Abbrechen</Button>
        <Button onClick={handleSave}>Speichern</Button>
      </DialogActions>
    </Dialog>
  );
}
