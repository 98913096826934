import * as React from 'react';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import AccountCircle from '@mui/icons-material/AccountCircle';
import MenuItem from '@mui/material/MenuItem';
import Menu from '@mui/material/Menu';
import { useAuthUser, useIsAuthenticated, useSignOut } from 'react-auth-kit';
import { Link } from 'react-router-dom';

interface HeaderProps {
  pageName: string;
  handleNavbarToggle: () => void;
}

export default function MenuAppBar(props: HeaderProps) {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const isAuthenticated = useIsAuthenticated();
  const authUser = useAuthUser();
  const signOut = useSignOut();
  const username = authUser()?.username || '';

  const handleMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const logout = () => {
    signOut();
    handleClose();
  };

  return (
    <Toolbar>
      <IconButton
        size='large'
        edge='start'
        color='inherit'
        aria-label='menu'
        sx={{
          mr: 2,
          display: { xs: 'block', sm: 'none' },
        }}
        onClick={props.handleNavbarToggle}
      >
        <MenuIcon />
      </IconButton>
      <Typography variant='h6' component='div' sx={{ flexGrow: 1 }}>
        {props.pageName}
      </Typography>
      <div>
        {isAuthenticated() && <span>Logged in as: {username}</span>}
        <IconButton
          size='large'
          aria-label='account of current user'
          aria-controls='menu-appbar'
          aria-haspopup='true'
          onClick={handleMenu}
          color={isAuthenticated() ? 'inherit' : 'error'}
        >
          <AccountCircle />
        </IconButton>
        <Menu
          id='menu-appbar'
          anchorEl={anchorEl}
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
          keepMounted
          transformOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
          open={Boolean(anchorEl)}
          onClose={handleClose}
        >
          {!isAuthenticated() && (
            <MenuItem onClick={handleClose}>
              <Link to='/signin'>Login</Link>
            </MenuItem>
          )}
          {isAuthenticated() && (
            <MenuItem onClick={logout}>
              <Link to='/signin'>Logout</Link>
            </MenuItem>
          )}
          {isAuthenticated() && (
            <MenuItem>
              <Link to='/changepassword'>Passwort ändern</Link>
            </MenuItem>
          )}
        </Menu>
      </div>
    </Toolbar>
  );
}
