import { GridColumns, GridRowsProp } from '@mui/x-data-grid';
import { useEffect, useState } from 'react';
import StandardTable from '../common/Component/StandardTable';
import LinkService from '../system/LinkService';
import useApiCall from '../system/useApiCall';
import AvatarDataDialog from './AvatarDataDialog';
import AvatarObjectTO from './AvatarObjectTO';

export default function AvatarPage() {
  const [editRow, setEditRow] = useState<number | null>(null);
  const [openDataDialog, setOpenDataDialog] = useState<boolean>(false);
  const [saveCount, setSaveCount] = useState<number>(0);
  const [avatarObjectList, setAvatarObjectList] = useState<AvatarObjectTO[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const apiCall = useApiCall();

  useEffect(() => {
    getAvatarObjectAll();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [saveCount]);

  useEffect(() => {
    if (editRow === null && openDataDialog) {
      setOpenDataDialog(false);
    } else if (editRow !== null && !openDataDialog) {
      setOpenDataDialog(true);
    }
  }, [editRow, openDataDialog]);

  const getAvatarObjectAll = () => {
    setIsLoading(true);
    apiCall.get(LinkService.getAllAvatarObjects()).then((data) => {
      if (data) {
        const list: AvatarObjectTO[] = data;
        setAvatarObjectList(list);
        setIsLoading(false);
      }
    });
  };

  const deleteAvatarObjectData = (id: number) => {
    apiCall.delete(LinkService.deleteAvatarObject(id), 'Datensatz gelöscht.').then((data) => {
      getAvatarObjectAll();
    });
  };

  const getEditData = () => {
    const avatarObjectData = avatarObjectList.find((item: AvatarObjectTO) => item.id === editRow);
    return avatarObjectData;
  };

  const columns: GridColumns = [
    { field: 'id', headerName: 'ID', type: 'number', width: 40, editable: false },
    { field: 'name', headerName: 'Name', flex: 1, editable: false },
    { field: 'bodyPart', headerName: 'Körperteil', flex: 1, editable: false },
    { field: 'fileName', headerName: 'Dateiname', flex: 1, editable: false },
    { field: 'dropCategory', headerName: 'Drop-Kategorie', flex: 1, editable: false },
    { field: 'dropWatchTimeActive', headerName: 'Drop Watch Time aktiv', type: 'boolean', flex: 1, editable: false },
    {
      field: 'dropChannelPointsActive',
      headerName: 'Drop Kanalpunkte aktiv',
      type: 'boolean',
      flex: 1,
      editable: false,
    },
    {
      field: 'unlockWatchTime',
      headerName: 'Freischalt-Watchtime (Minuten)',
      type: 'number',
      flex: 1,
      editable: false,
    },
    { field: 'unlockChannelPoints', headerName: 'Freischalt-Kanalpunkte', type: 'number', flex: 1, editable: false },
  ];

  const rows: GridRowsProp = [...avatarObjectList];

  return (
    <div>
      <AvatarDataDialog
        openDialog={openDataDialog}
        setEditRow={setEditRow}
        editData={editRow ? getEditData() : undefined}
        saveCount={saveCount}
        setSaveCount={setSaveCount}
      />
      <StandardTable
        editRowNumber={setEditRow}
        columns={columns}
        rows={rows}
        deleteFunction={deleteAvatarObjectData}
        isLoading={isLoading}
      />
    </div>
  );
}
