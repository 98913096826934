import { format, parseISO } from 'date-fns';

export default class Util {
  static isStringInt(value: string): boolean {
    const regex = /^[0-9]+$/;
    return value.match(regex) !== null;
  }

  static parseStringToInt(value: string): number {
    return Number.parseInt(value);
  }

  static dateFormatter(value: unknown): string {
    let formattedDate = '';
    if (!value) {
      return formattedDate;
    } else if (typeof value === 'string') {
      formattedDate = format(parseISO(value), 'dd.MM.yyyy HH:mm');
    } else {
      try {
        formattedDate = format(parseISO(value as string), 'dd.MM.yyyy HH:mm');
      } catch (error) {
        formattedDate = 'unkown date format';
      }
    }
    return formattedDate;
  }
}
