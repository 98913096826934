import * as React from 'react';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { useState } from 'react';

import useApiCall from '../system/useApiCall';
import LinkService from '../system/LinkService';
import { Checkbox, FormControlLabel, FormGroup } from '@mui/material';
import BotCommandTO from './BotCommandTO';

interface BotCommandDataDialogProps {
  editData?: BotCommandTO;
  openDialog: boolean;
  setEditRow: React.Dispatch<React.SetStateAction<number | null>>;
  saveCount: number;
  setSaveCount: React.Dispatch<React.SetStateAction<number>>;
}

export default function BotCommandDataDialog(props: BotCommandDataDialogProps) {
  const apiCall = useApiCall();
  const botCommandNewDataInit: BotCommandTO = {
    id: -1,
    command: '',
    description: '',
    activeModifiedOn: new Date(),
    active: false,
  };
  const [botCommandData, setBotCommandData] = useState<BotCommandTO>(
    props.editData ? props.editData : botCommandNewDataInit
  );

  React.useEffect(() => {
    if (props.editData) {
      setBotCommandData(props.editData);
    }
  }, [props.editData]);

  const handleCancel = () => {
    handleClose();
  };

  const handleSave = () => {
    apiCall.post(LinkService.botCommandPost(), botCommandData, 'Speichern erfolgreich.').then((data) => {
      handleClose();
      props.setSaveCount(props.saveCount + 1);
    });
  };

  const handleClose = () => {
    setBotCommandData(botCommandNewDataInit);
    props.setEditRow(null);
  };

  const title = botCommandData.id === -1 ? 'Neuen Command hinzufügen' : 'Command bearbeiten';
  return (
    <Dialog open={props.openDialog}>
      <DialogTitle>{title}</DialogTitle>
      <DialogContent>
        <TextField
          margin='dense'
          id='command'
          label='Command'
          fullWidth
          variant='standard'
          value={botCommandData.command}
          onChange={(e) => setBotCommandData({ ...botCommandData, command: e.target.value })}
        />{' '}
        <TextField
          margin='dense'
          id='description'
          label='Beschreibung'
          fullWidth
          variant='standard'
          value={botCommandData.description}
          onChange={(e) => setBotCommandData({ ...botCommandData, description: e.target.value })}
        />{' '}
        <FormGroup>
          <FormControlLabel
            control={
              <Checkbox
                checked={botCommandData.active}
                onChange={(e) => setBotCommandData({ ...botCommandData, active: e.target.checked })}
              />
            }
            label='aktiv'
          />
        </FormGroup>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleCancel}>Abbrechen</Button>
        <Button onClick={handleSave}>Speichern</Button>
      </DialogActions>
    </Dialog>
  );
}
