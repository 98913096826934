import React, { useEffect, useState } from 'react';
import LinkService from '../../system/LinkService';
import useApiCall from '../../system/useApiCall';
import StandardTable from '../../common/Component/StandardTable';
import { GridColumns, GridRowsProp } from '@mui/x-data-grid';
import SottGameDataDialog from './SottGameDataDialog';
import DropdownTO from '../../common/TO/DropdownTO';
import { MenuItem, Select } from '@mui/material';
import { useSottEventDropdownData } from '../Helper/SottHelperHooks';
import SottGameTO from '../TO/SottGameTO';

export default function SottGamePage() {
  const [editRow, setEditRow] = useState<number | null>(null);
  const [openDataDialog, setOpenDataDialog] = useState<boolean>(false);
  const [saveCount, setSaveCount] = useState<number>(0);
  const [sottGameList, setSottGameList] = useState<SottGameTO[]>([]);
  const [selectedSottEvent, setSelectedSottEvent] = useState<number>(-1);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const apiCall = useApiCall();
  const SottEventDropdownData = useSottEventDropdownData();

  useEffect(() => {
    if (SottEventDropdownData.length > 0) {
      getSottEventActiveId();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [SottEventDropdownData.length]);

  useEffect(() => {
    getSottGameAll();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [saveCount, selectedSottEvent]);

  useEffect(() => {
    if (editRow === null && openDataDialog) {
      setOpenDataDialog(false);
    } else if (editRow !== null && !openDataDialog) {
      setOpenDataDialog(true);
    }
  }, [editRow, openDataDialog]);

  const getSottGameAll = () => {
    if (selectedSottEvent > -1) {
      setIsLoading(true);
      apiCall.get(LinkService.sottGameBySottEventId(selectedSottEvent)).then((data) => {
        if (data) {
          const list: SottGameTO[] = data;
          setSottGameList(list);
          setIsLoading(false);
        }
      });
    }
  };

  const getSottEventActiveId = () => {
    apiCall.get(LinkService.sottEventActiveId()).then((data) => {
      if (data) {
        const eventId: number = data;
        setSelectedSottEvent(eventId);
      }
    });
  };

  const deleteSottGameData = (id: number) => {
    apiCall.delete(LinkService.sottGameDelete(id), 'Datensatz gelöscht.').then((data) => {
      getSottGameAll();
    });
  };

  const getEditData = () => {
    const sottGameData = sottGameList.find((item: SottGameTO) => item.id === editRow);
    return sottGameData;
  };

  const columns: GridColumns = [
    { field: 'id', headerName: 'Nr.', type: 'number', width: 40, editable: false },
    { field: 'name', headerName: 'Name', flex: 1, editable: true },
    { field: 'rounds', headerName: 'Runden', flex: 1, editable: true },
  ];

  const rows: GridRowsProp = [...sottGameList];

  return (
    <div>
      <Select
        margin='dense'
        id='sottevent'
        label='SotT Event'
        variant='standard'
        value={selectedSottEvent}
        onChange={(e) => setSelectedSottEvent(e.target.value as number)}
      >
        <MenuItem key={'sottDropdown_please_choose'} value={-1}>
          Event auswählen
        </MenuItem>
        {SottEventDropdownData.map((dropdownItem: DropdownTO) => (
          <MenuItem key={'sottDropdown_' + dropdownItem.id} value={dropdownItem.id}>
            {dropdownItem.name}
          </MenuItem>
        ))}
      </Select>
      <SottGameDataDialog
        openDialog={openDataDialog}
        setEditRow={setEditRow}
        editData={editRow ? getEditData() : undefined}
        saveCount={saveCount}
        setSaveCount={setSaveCount}
        sottEventDropdown={SottEventDropdownData}
        preselectedSottEvent={selectedSottEvent}
      />
      <StandardTable
        saveURL={LinkService.sottTeamPost()}
        editRowNumber={setEditRow}
        columns={columns}
        rows={rows}
        deleteFunction={deleteSottGameData}
        isLoading={isLoading}
      />
    </div>
  );
}
