import { Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import LinkService from '../../system/LinkService';
import useApiCall from '../../system/useApiCall';
import WuEventUserTO from '../TO/WuEventUserTO';
import WuParticipantBotTO from '../TO/WuParticipantBotTO';

export default function WuEventDeathListPage() {
  const [wuEvent, setWuEvent] = useState<WuEventUserTO>();
  const [deathList, setDeathList] = useState<WuParticipantBotTO[]>([]);
  const apiCall = useApiCall();

  useEffect(() => {
    getWuEventInfo();
    getDeathList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getWuEventInfo = () => {
    apiCall.get(LinkService.wuEventRegisterInfo()).then((data: WuEventUserTO) => {
      if (data) {
        setWuEvent(data);
      }
    });
  };

  const getDeathList = () => {
    apiCall.get(LinkService.wuEventDeathList()).then((data) => {
      if (data) {
        const list: WuParticipantBotTO[] = data;
        setDeathList(list);
      }
    });
  };

  return (
    <div>
      <Typography component='h1' variant='h5'>
        {wuEvent ? wuEvent.name + ' Live Ticker :xicanF::' : 'Es ist gerade kein Wer Überlebt aktiv.'}
      </Typography>
      {deathList.map((item: WuParticipantBotTO) => {
        if (item.causeOfDeath) {
          return (
            <span>
              {item.position}. {item.nameOfSim} - {item.causeOfDeath}
              <br />
            </span>
          );
        }
        return (
          <span>
            {item.position}.
            <br />
          </span>
        );
      })}
    </div>
  );
}
