import * as React from 'react';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import useApiCall from '../../system/useApiCall';
import LinkService from '../../system/LinkService';
import { useState } from 'react';
import SottSurveyTO from '../TO/SottSurveyTO';
import { Stepper, Step, StepLabel, MobileStepper } from '@mui/material';
import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';
import { OptionsObject, useSnackbar } from 'notistack';

const theme = createTheme();

export default function SottSurveyPage() {
  const apiCall = useApiCall();
  const sottSurveyDataInit: SottSurveyTO = {
    questions: [
      { question: 'Haus', answer: '' },
      { question: 'Svenna', answer: '' },
      { question: 'PC', answer: '' },
      { question: 'Heada', answer: '' },
      { question: 'Bier', answer: '' },
      { question: 'Elky', answer: '' },
      { question: 'Natur', answer: '' },
      { question: 'Iken', answer: '' },
      { question: 'Hund', answer: '' },
      { question: 'Buch', answer: '' },
    ],
  };

  const [formData, setFormData] = useState(sottSurveyDataInit);
  const [activeStep, setActiveStep] = React.useState(0);
  const { enqueueSnackbar } = useSnackbar();
  const variantError: OptionsObject = { variant: 'error', style: { whiteSpace: 'pre-line' } };

  const handleNext = () => {
    if (activeStep === formData.questions.length) {
      // validate data, only one word per answer allowed.
      const validationErrors: string[] = [];
      formData.questions.forEach((question, indexQ) => {
        if (question.answer.includes(' ')) {
          validationErrors.push(
            'Seite ' +
              (indexQ + 2) +
              '/' +
              (formData.questions.length + 1) +
              ': ' +
              question.question +
              ' - Antwort: ' +
              question.answer +
              ' -> Es darf nur genau ein Wort sein.'
          );
        }
      });

      if (validationErrors.length > 0) {
        let errorMsg = '';
        validationErrors.forEach((error) => {
          errorMsg += error + '\n';
        });
        enqueueSnackbar(errorMsg, variantError);
        return;
      }

      apiCall
        .post(LinkService.sottSurveyPost(), formData, 'Antworten erfolgreich übermittelt.')
        .then((data: unknown) => {
          setActiveStep((prevActiveStep) => prevActiveStep + 1);
        })
        .catch((data) => {
          console.log('dataerror', data);
        });
    } else {
      setActiveStep((prevActiveStep) => prevActiveStep + 1);
    }
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  return (
    <ThemeProvider theme={theme}>
      <Container component='main' maxWidth='md'>
        <CssBaseline />
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <Typography component='div' variant='body1'>
            {activeStep !== 0 &&
              'Welche Begriffe verbindest du mit den folgenden Wörtern? Schreibe jeweils einen einzelnen Begriff zu jedem Wort.'}
          </Typography>
        </Box>
        <Box
          sx={{
            marginTop: 2,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <Stepper activeStep={activeStep} sx={{ display: { xs: 'none', md: 'inherit' } }}>
            <Step key={'question_intro'}>
              <StepLabel>{activeStep === 0 ? 'Einführung' : <s>Einführung</s>}</StepLabel>
            </Step>
            {formData.questions.map((question, index) => {
              const stepProps: { completed?: boolean } = {};
              let title = '???';
              if (index <= activeStep - 1) {
                title = question.question;
              }
              return (
                <Step key={'question_' + index} {...stepProps}>
                  <StepLabel>{index > activeStep - 2 ? title : <s>{title}</s>}</StepLabel>
                </Step>
              );
            })}
          </Stepper>
          {activeStep - 1 === formData.questions.length && (
            <React.Fragment>
              <Typography sx={{ mt: 2, mb: 1 }}>Vielen Dank für deine Teilnahme!</Typography>
            </React.Fragment>
          )}

          {activeStep !== 0 && activeStep - 1 < formData.questions.length && (
            <React.Fragment>
              <Typography sx={{ mt: 2, mb: 1 }}>
                Was verbindest du mit: <b>{formData.questions[activeStep - 1].question}</b>?
              </Typography>
              <Box sx={{ flex: '1 1 auto' }} />
              <TextField
                key={'question' + (activeStep - 1) + '_answer'}
                margin='dense'
                size='small'
                fullWidth
                autoFocus
                id={'question' + (activeStep - 1) + '_answer'}
                name={'question' + (activeStep - 1) + '_answer'}
                value={formData.questions[activeStep - 1].answer}
                onChange={(e) => {
                  const questionList = formData.questions;
                  questionList[activeStep - 1].answer = e.target.value.trim();
                  setFormData({ ...formData, questions: questionList });
                }}
              />
            </React.Fragment>
          )}
          {activeStep === 0 && (
            <React.Fragment>
              <Typography sx={{ mt: 2, mb: 1 }}>
                Hallöchen! <br />
                Du bekommst gleich nacheinander 10 Begriffe angezeigt. Bitte schreibe jeweils ein Wort auf, dass du mit
                diesem Begriff verbindest. Denk nicht zu viel darüber nach. <br />
                Vielen Dank und los geht's!
              </Typography>
            </React.Fragment>
          )}

          <Box sx={{ flex: '1 1 auto' }} />
          {activeStep - 1 < formData.questions.length && (
            <MobileStepper
              variant='text'
              steps={formData.questions.length + 1}
              position='static'
              activeStep={activeStep}
              nextButton={
                <Button size='small' onClick={handleNext}>
                  {activeStep === formData.questions.length ? 'Antworten abschicken' : 'Weiter'}
                  {theme.direction === 'rtl' ? <KeyboardArrowLeft /> : <KeyboardArrowRight />}
                </Button>
              }
              backButton={
                <Button size='small' onClick={handleBack} disabled={activeStep === 0}>
                  {theme.direction === 'rtl' ? <KeyboardArrowRight /> : <KeyboardArrowLeft />}
                  Zurück
                </Button>
              }
            />
          )}
        </Box>
      </Container>
    </ThemeProvider>
  );
}
