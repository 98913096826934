import * as React from 'react';
import List from '@mui/material/List';
import Divider from '@mui/material/Divider';

import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import FiberManualRecordOutlinedIcon from '@mui/icons-material/FiberManualRecordOutlined';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import { Collapse, Toolbar } from '@mui/material';
import { Link } from 'react-router-dom';
import { useIsAuthenticated } from 'react-auth-kit';
import { ERole } from '../model/ERole';
import { useAuthRoleCheck } from './Helper/CommonHelper';

interface NavigationMenuProps {
  setPageName: React.Dispatch<React.SetStateAction<string>>;
}

export default function NavigationMenu(props: NavigationMenuProps) {
  const [openSott, setOpenSott] = React.useState(true);
  const [openWu, setOpenWu] = React.useState(true);
  const [openGame, setOpenGame] = React.useState(true);
  const [openAvatar, setOpenAvatar] = React.useState(true);
  const isAuthenticated = useIsAuthenticated();
  const { checkHasAnyRole, checkHasRoleStartsWith } = useAuthRoleCheck();

  const setPageName = (name: string) => {
    props.setPageName(name);
  };

  return (
    <div>
      <Toolbar />
      <Divider />
      <List>
        <ListItem component={Link} to='/wueventregistration' onClick={() => setPageName('Wer Überlebt Anmeldung')}>
          <ListItemIcon>
            <FiberManualRecordOutlinedIcon />
          </ListItemIcon>
          <ListItemText primary={'Wer Überlebt Anmeldung'}></ListItemText>
        </ListItem>
        <ListItem component={Link} to='/wueventdeathlist' onClick={() => setPageName('Wer Überlebt Todesliste')}>
          <ListItemIcon>
            <FiberManualRecordOutlinedIcon />
          </ListItemIcon>
          <ListItemText primary={'Wer Überlebt Todesliste'}></ListItemText>
        </ListItem>
      </List>
      <Divider />
      {isAuthenticated() && (
        <List>
          {checkHasAnyRole(ERole.ROLE_ADMIN) && (
            <>
              <ListItem component={Link} to='/user' onClick={() => setPageName('User')}>
                <ListItemIcon>
                  <FiberManualRecordOutlinedIcon />
                </ListItemIcon>
                <ListItemText primary={'User'}></ListItemText>
              </ListItem>
            </>
          )}
          {checkHasRoleStartsWith(ERole.ROLE_XICANMEOW) && (
            <>
              {checkHasAnyRole(ERole.ROLE_XICANMEOW, ERole.ROLE_MODERATOR) && (
                <ListItem component={Link} to='/botcommand' onClick={() => setPageName('Bot Commands')}>
                  <ListItemIcon>
                    <FiberManualRecordOutlinedIcon />
                  </ListItemIcon>
                  <ListItemText primary={'Bot Commands'}></ListItemText>
                </ListItem>
              )}
              <>
                <ListItem onClick={() => setOpenAvatar(!openAvatar)}>
                  <ListItemIcon>
                    <FiberManualRecordOutlinedIcon />
                  </ListItemIcon>
                  <ListItemText primary='Avatar' />
                  {openAvatar ? <ExpandLess /> : <ExpandMore />}
                </ListItem>
                
                <Collapse in={openAvatar} timeout='auto' unmountOnExit>
                  <List component='div' disablePadding>
                    {checkHasAnyRole(ERole.ROLE_XICANMEOW, ERole.ROLE_MODERATOR) && (
                      <ListItem
                        sx={{ pl: 4 }}
                        component={Link}
                        to='/avatar'
                        onClick={() => setPageName('Avatar Objekte')}
                      >
                        <ListItemIcon>
                          <FiberManualRecordOutlinedIcon />
                        </ListItemIcon>
                        <ListItemText primary={'Avatar Objekte'}></ListItemText>
                      </ListItem>
                    )}
                    {checkHasAnyRole(ERole.ROLE_XICANMEOW, ERole.ROLE_MODERATOR) && (
                      <ListItem
                        sx={{ pl: 4 }}
                        component={Link}
                        to='/avatarcontrol'
                        onClick={() => setPageName('Avatar Control')}
                      >
                        <ListItemIcon>
                          <FiberManualRecordOutlinedIcon />
                        </ListItemIcon>
                        <ListItemText primary={'Avatar Control'}></ListItemText>
                      </ListItem>
                    )}
                  </List>
                </Collapse>
              </>
              {checkHasAnyRole(ERole.ROLE_XICANMEOW, ERole.ROLE_MODERATOR) && (
                <ListItem component={Link} to='/supportdefinition' onClick={() => setPageName('Support Definition')}>
                  <ListItemIcon>
                    <FiberManualRecordOutlinedIcon />
                  </ListItemIcon>
                  <ListItemText primary={'Support Definition'}></ListItemText>
                </ListItem>
              )}
              <>
                <ListItem onClick={() => setOpenGame(!openGame)}>
                  <ListItemIcon>
                    <FiberManualRecordOutlinedIcon />
                  </ListItemIcon>
                  <ListItemText primary='Games' />
                  {openGame ? <ExpandLess /> : <ExpandMore />}
                </ListItem>

                <Collapse in={openGame} timeout='auto' unmountOnExit>
                  <List component='div' disablePadding>
                    {checkHasAnyRole(ERole.ROLE_XICANMEOW, ERole.ROLE_MODERATOR) && (
                      <ListItem
                        sx={{ pl: 4 }}
                        component={Link}
                        to='/gamebdcontrol'
                        onClick={() => setPageName('Game Bd Control')}
                      >
                        <ListItemIcon>
                          <FiberManualRecordOutlinedIcon />
                        </ListItemIcon>
                        <ListItemText primary={'Game Bd Control'}></ListItemText>
                      </ListItem>
                    )}
                    {checkHasAnyRole(ERole.ROLE_XICANMEOW, ERole.ROLE_MODERATOR) && (
                      <ListItem
                        sx={{ pl: 4 }}
                        component={Link}
                        to='/gamexmas23control'
                        onClick={() => setPageName('Game Xmas23 Control')}
                      >
                        <ListItemIcon>
                          <FiberManualRecordOutlinedIcon />
                        </ListItemIcon>
                        <ListItemText primary={'Game Xmas23 Control'}></ListItemText>
                      </ListItem>
                    )}
                    {checkHasAnyRole(ERole.ROLE_XICANMEOW, ERole.ROLE_MODERATOR) && (
                      <ListItem
                        sx={{ pl: 4 }}
                        component={Link}
                        to='/gamexmas23calenderprize'
                        onClick={() => setPageName('Game Xmas23 Preise')}
                      >
                        <ListItemIcon>
                          <FiberManualRecordOutlinedIcon />
                        </ListItemIcon>
                        <ListItemText primary={'Game Xmas23 Preise'}></ListItemText>
                      </ListItem>
                    )}
                    {checkHasAnyRole(ERole.ROLE_XICANMEOW, ERole.ROLE_MODERATOR) && (
                      <ListItem
                        sx={{ pl: 4 }}
                        component={Link}
                        to='/gameeaster24control'
                        onClick={() => setPageName('Game Easter24 Control')}
                      >
                        <ListItemIcon>
                          <FiberManualRecordOutlinedIcon />
                        </ListItemIcon>
                        <ListItemText primary={'Game Easter24 Control'}></ListItemText>
                      </ListItem>
                    )}
                  </List>
                </Collapse>
              </>

              {checkHasAnyRole(ERole.ROLE_XICANMEOW, ERole.ROLE_XICANMEOW_GREETING) && (
                <ListItem component={Link} to='/greeting' onClick={() => setPageName('Greeting')}>
                  <ListItemIcon>
                    <FiberManualRecordOutlinedIcon />
                  </ListItemIcon>
                  <ListItemText primary={'Greeting'}></ListItemText>
                </ListItem>
              )}
            </>
          )}
          {(checkHasAnyRole(ERole.ROLE_XICANMEOW) || checkHasRoleStartsWith(ERole.ROLE_XICANMEOW + '_WU')) && (
            <>
              <ListItem onClick={() => setOpenWu(!openWu)}>
                <ListItemIcon>
                  <FiberManualRecordOutlinedIcon />
                </ListItemIcon>
                <ListItemText primary='Wer überlebt' />
                {openWu ? <ExpandLess /> : <ExpandMore />}
              </ListItem>

              <Collapse in={openWu} timeout='auto' unmountOnExit>
                <List component='div' disablePadding>
                  {checkHasAnyRole(ERole.ROLE_XICANMEOW, ERole.ROLE_XICANMEOW_WU_EVENT) && (
                    <ListItem sx={{ pl: 4 }} component={Link} to='/wuevent' onClick={() => setPageName('WÜ Event')}>
                      <ListItemIcon>
                        <FiberManualRecordOutlinedIcon />
                      </ListItemIcon>
                      <ListItemText primary={'WÜ Event'}></ListItemText>
                    </ListItem>
                  )}
                  {checkHasAnyRole(ERole.ROLE_XICANMEOW, ERole.ROLE_XICANMEOW_WU_PARTICIPANT) && (
                    <ListItem
                      sx={{ pl: 4 }}
                      component={Link}
                      to='/wuparticipant'
                      onClick={() => setPageName('WÜ Teilnehmer')}
                    >
                      <ListItemIcon>
                        <FiberManualRecordOutlinedIcon />
                      </ListItemIcon>
                      <ListItemText primary={'WÜ Teilnehmer'}></ListItemText>
                    </ListItem>
                  )}
                  {checkHasAnyRole(ERole.ROLE_XICANMEOW, ERole.ROLE_XICANMEOW_WU_EVENTLIVE) && (
                    <ListItem
                      sx={{ pl: 4 }}
                      component={Link}
                      to='/wueventlive'
                      onClick={() => setPageName('WÜ Event Live')}
                    >
                      <ListItemIcon>
                        <FiberManualRecordOutlinedIcon />
                      </ListItemIcon>
                      <ListItemText primary={'WÜ Event Live'}></ListItemText>
                    </ListItem>
                  )}
                  {checkHasAnyRole(ERole.ROLE_XICANMEOW, ERole.ROLE_XICANMEOW_WU_BINGOLIST) && (
                    <ListItem
                      sx={{ pl: 4 }}
                      component={Link}
                      to='/wubingolist'
                      onClick={() => setPageName('WÜ Bingos')}
                    >
                      <ListItemIcon>
                        <FiberManualRecordOutlinedIcon />
                      </ListItemIcon>
                      <ListItemText primary={'WÜ Bingos'}></ListItemText>
                    </ListItem>
                  )}
                  {checkHasAnyRole(ERole.ROLE_XICANMEOW, ERole.ROLE_XICANMEOW_WU_CAUSEOFDEATH) && (
                    <ListItem
                      sx={{ pl: 4 }}
                      component={Link}
                      to='/wucauseofdeath'
                      onClick={() => setPageName('WÜ Todesursache')}
                    >
                      <ListItemIcon>
                        <FiberManualRecordOutlinedIcon />
                      </ListItemIcon>
                      <ListItemText primary={'WÜ Todesursache'}></ListItemText>
                    </ListItem>
                  )}
                </List>
              </Collapse>
            </>
          )}

          {checkHasAnyRole(ERole.ROLE_SOTT) && (
            <>
              <ListItem onClick={() => setOpenSott(!openSott)}>
                <ListItemIcon>
                  <FiberManualRecordOutlinedIcon />
                </ListItemIcon>
                <ListItemText primary='SotT' />
                {openSott ? <ExpandLess /> : <ExpandMore />}
              </ListItem>

              <Collapse in={openSott} timeout='auto' unmountOnExit>
                <List component='div' disablePadding>
                  <ListItem sx={{ pl: 4 }} component={Link} to='/sottevent' onClick={() => setPageName('SotT Event')}>
                    <ListItemIcon>
                      <FiberManualRecordOutlinedIcon />
                    </ListItemIcon>
                    <ListItemText primary={'SotT Event'}></ListItemText>
                  </ListItem>
                  <ListItem sx={{ pl: 4 }} component={Link} to='/sottteam' onClick={() => setPageName('SotT Teams')}>
                    <ListItemIcon>
                      <FiberManualRecordOutlinedIcon />
                    </ListItemIcon>
                    <ListItemText primary={'SotT Teams'}></ListItemText>
                  </ListItem>
                  <ListItem sx={{ pl: 4 }} component={Link} to='/sottgame' onClick={() => setPageName('SotT Games')}>
                    <ListItemIcon>
                      <FiberManualRecordOutlinedIcon />
                    </ListItemIcon>
                    <ListItemText primary={'SotT Games'}></ListItemText>
                  </ListItem>
                  <ListItem
                    sx={{ pl: 4 }}
                    component={Link}
                    to='/sottlive'
                    onClick={() => setPageName('SotT Live Event')}
                  >
                    <ListItemIcon>
                      <FiberManualRecordOutlinedIcon />
                    </ListItemIcon>
                    <ListItemText primary={'SotT Live Event'}></ListItemText>
                  </ListItem>
                </List>
              </Collapse>
            </>
          )}
        </List>
      )}
    </div>
  );
}
