import * as React from 'react';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import useApiCall from '../../system/useApiCall';
import LinkService from '../../system/LinkService';
import WuEventTO from '../TO/WuEventTO';
import { useState } from 'react';
import Util from '../../system/Util';
import DateAndTimePicker from '../../common/Component/DateAndTimePicker';

interface WuEventDataDialogProps {
  editData?: WuEventTO;
  openDialog: boolean;
  setEditRow: React.Dispatch<React.SetStateAction<number | null>>;
  saveCount: number;
  setSaveCount: React.Dispatch<React.SetStateAction<number>>;
}

export default function WuEventDataDialog(props: WuEventDataDialogProps) {
  const apiCall = useApiCall();
  const wuEventDataInit: WuEventTO = {
    id: -1,
    name: '',
    eventNumber: -1,
    eventDate: null,
    registerStartDate: null,
    registerStopDate: null,
    maxParticipants: 80,
    maxRegistrations: null,
    registrationFull: false,
    hashtag: '',
    theme: '',
    description: '',
    conditions: '',
    active: false,
  };
  const [wuEventData, setWuEventData] = useState<WuEventTO>(props.editData ? props.editData : wuEventDataInit);

  React.useEffect(() => {
    if (props.editData) {
      setWuEventData(props.editData);
    }
  }, [props.editData]);

  const handleCancel = () => {
    handleClose();
  };

  const handleSave = () => {
    apiCall.post(LinkService.wuEventPost(), wuEventData, 'Speichern erfolgreich.').then((data) => {
      handleClose();
      props.setSaveCount(props.saveCount + 1);
    });
  };

  const handleClose = () => {
    setWuEventData(wuEventDataInit);
    props.setEditRow(null);
  };

  const title = wuEventData.id === -1 ? 'Neues Wer Überlebt Event hinzufügen' : 'Wer Überlebt Event bearbeiten';

  return (
    <Dialog open={props.openDialog}>
      <DialogTitle>{title}</DialogTitle>
      <DialogContent>
        <TextField
          margin='dense'
          id='name'
          label='Name'
          fullWidth
          variant='standard'
          value={wuEventData.name}
          onChange={(e) => setWuEventData({ ...wuEventData, name: e.target.value })}
        />{' '}
        <TextField
          margin='dense'
          id='eventNumber'
          label='Event-Nummer'
          fullWidth
          variant='standard'
          type='number'
          value={wuEventData.eventNumber}
          onChange={(e) =>
            setWuEventData({
              ...wuEventData,
              eventNumber: Util.isStringInt(e.target.value) ? Util.parseStringToInt(e.target.value) : -1,
            })
          }
        />{' '}
        <TextField
          required
          margin='dense'
          id='hashtag'
          label='Hashtag'
          fullWidth
          variant='standard'
          value={wuEventData.hashtag}
          onChange={(e) => setWuEventData({ ...wuEventData, hashtag: e.target.value })}
        />{' '}
        <TextField
          margin='dense'
          id='theme'
          label='Thema'
          fullWidth
          variant='standard'
          value={wuEventData.theme}
          onChange={(e) => setWuEventData({ ...wuEventData, theme: e.target.value })}
        />{' '}
        <TextField
          margin='dense'
          multiline
          id='description'
          label='Beschreibung'
          fullWidth
          variant='standard'
          value={wuEventData.description}
          onChange={(e) => setWuEventData({ ...wuEventData, description: e.target.value })}
        />{' '}
        <TextField
          margin='dense'
          multiline
          id='conditions'
          label='Bedingungen'
          fullWidth
          variant='standard'
          value={wuEventData.conditions}
          onChange={(e) => setWuEventData({ ...wuEventData, conditions: e.target.value })}
        />{' '}
        <DateAndTimePicker
          label='Event-Datum'
          value={wuEventData.eventDate}
          onChange={(e: Date | null) => setWuEventData({ ...wuEventData, eventDate: e })}
        />{' '}
        <DateAndTimePicker
          label='Anmeldestart'
          value={wuEventData.registerStartDate}
          onChange={(e: Date | null) => setWuEventData({ ...wuEventData, registerStartDate: e })}
        />{' '}
        <DateAndTimePicker
          label='Anmeldestop'
          value={wuEventData.registerStopDate}
          onChange={(e: Date | null) => setWuEventData({ ...wuEventData, registerStopDate: e })}
        />{' '}
        <TextField
          margin='dense'
          id='maxParticipants'
          label='Max. Teilnehmeranzahl'
          fullWidth
          variant='standard'
          type='number'
          value={wuEventData.maxParticipants}
          onChange={(e) =>
            setWuEventData({
              ...wuEventData,
              maxParticipants: Util.isStringInt(e.target.value) ? Util.parseStringToInt(e.target.value) : -1,
            })
          }
        />{' '}
        <TextField
          margin='dense'
          id='maxRegistrations'
          label='Max. Registrierungen'
          fullWidth
          variant='standard'
          type='number'
          value={wuEventData.maxRegistrations}
          onChange={(e) =>
            setWuEventData({
              ...wuEventData,
              maxRegistrations: Util.isStringInt(e.target.value) ? Util.parseStringToInt(e.target.value) : -1,
            })
          }
          required={true}
          // helperText="Incorrect entry."
          // error={Util.isStringInt(wuEventData.maxRegistrations) ? false : true}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={handleCancel}>Abbrechen</Button>
        <Button onClick={handleSave}>Speichern</Button>
      </DialogActions>
    </Dialog>
  );
}
