import React, { useEffect, useState } from 'react';
import LinkService from '../system/LinkService';
import useApiCall from '../system/useApiCall';
import StandardTable from '../common/Component/StandardTable';
import { GridColumns, GridRowsProp } from '@mui/x-data-grid';
import GameControlTO from './GameControlTO';
import Typography from '@mui/material/Typography/Typography';

export default function BotCommandPage() {
  const [controlList, setControlList] = useState<GameControlTO[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const apiCall = useApiCall();

  useEffect(() => {
    getCommandAll();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getCommandAll = () => {
    setIsLoading(true);
    apiCall.get(LinkService.getGameBdControlAll()).then((data) => {
      if (data) {
        const list: GameControlTO[] = data;
        setControlList(list);
        setIsLoading(false);
      }
    });
  };

  const columns: GridColumns = [
    { field: 'id', headerName: 'ID', type: 'number', width: 30, editable: false },
    { field: 'controlKey', headerName: 'Control Name', flex: 3, editable: false },
    { field: 'controlValue', headerName: 'Wert (editierbar)', flex: 2, editable: true },
    { field: 'controlValueType', headerName: 'Typ', flex: 2, editable: false },
    { field: 'description', headerName: 'Beschreibung (editierbar)', flex: 9, editable: true },
  ];

  const rows: GridRowsProp = [...controlList];

  return (
    <div>
      <Typography component='p' variant='caption'>
        Werte mit Doppelklick editieren.
      </Typography>
      <StandardTable
        saveURL={LinkService.postGameBdControl()}
        columns={columns}
        rows={rows}
        isLoading={isLoading}
        hideActions
        hideToolbar
      />
    </div>
  );
}
