export enum ERole {
  ROLE_USER = 'ROLE_USER',
  ROLE_MODERATOR = 'ROLE_MODERATOR',
  ROLE_ADMIN = 'ROLE_ADMIN',
  ROLE_SOTT = 'ROLE_SOTT',
  ROLE_XICANMEOW = 'ROLE_XICANMEOW',
  ROLE_XICANMEOW_WU_EVENT = 'ROLE_XICANMEOW_WU_EVENT',
  ROLE_XICANMEOW_WU_PARTICIPANT = 'ROLE_XICANMEOW_WU_PARTICIPANT',
  ROLE_XICANMEOW_WU_EVENTLIVE = 'ROLE_XICANMEOW_WU_EVENTLIVE',
  ROLE_XICANMEOW_WU_BINGOLIST = 'ROLE_XICANMEOW_WU_BINGOLIST',
  ROLE_XICANMEOW_WU_CAUSEOFDEATH = 'ROLE_XICANMEOW_WU_CAUSEOFDEATH',
  ROLE_XICANMEOW_GREETING = 'ROLE_XICANMEOW_GREETING',
  ROLE_XICANMEOW_LIMITED = 'ROLE_XICANMEOW_LIMITED',
}
