import * as React from 'react';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import useApiCall from '../../system/useApiCall';
import LinkService from '../../system/LinkService';
import { useEffect, useState } from 'react';
import { SurveyQuestionTO } from '../TO/SottSurveyTO';

const theme = createTheme();

export default function SottSurveyShowPage() {
  const apiCall = useApiCall();
  const [showData, setShowData] = useState<SurveyQuestionTO[]>([]);

  useEffect(() => {
    apiCall
      .get(LinkService.sottSurveyGetAll(), 'Antworten erfolgreich geholt.')
      .then((data: SurveyQuestionTO[]) => {
        setShowData(data);
      })
      .catch((data) => {
        console.log('dataerror', data);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  function getCSV() {
    apiCall.get(LinkService.sottSurveyGetAnswersAsCSV(), 'Antworten erfolgreich runtergeladen.').then((data) => {
      const blob = new Blob([data], { type: 'text/csv' });
      const a = document.createElement('a');
      a.download = 'SotT_survey.csv';
      a.href = window.URL.createObjectURL(blob);
      const clickEvt = new MouseEvent('click', {
        view: window,
        bubbles: true,
        cancelable: true,
      });
      a.dispatchEvent(clickEvt);
      a.remove();
    });
  }

  return (
    <ThemeProvider theme={theme}>
      <Container component='main' maxWidth='md'>
        <CssBaseline />
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <Button onClick={getCSV} variant='outlined'>
            {' '}
            Ergebnis als CSV runterladen
          </Button>
          <Typography component='div' variant='body1'>
            {'Antworten:'}
          </Typography>
        </Box>
        <Box
          sx={{
            marginTop: 2,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          {showData &&
            showData.length > 0 &&
            showData.map((item, index) => {
              return (
                <div key={'answer' + index}>
                  {item.question}: {item.answer}
                  <br />
                </div>
              );
            })}
        </Box>
      </Container>
    </ThemeProvider>
  );
}
