import * as React from 'react';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import useApiCall from '../../system/useApiCall';
import LinkService from '../../system/LinkService';
import { useState } from 'react';
import DropdownTO from '../../common/TO/DropdownTO';

interface WuCauseOfDeathDataDialogProps {
  editData?: DropdownTO;
  openDialog: boolean;
  setEditRow: React.Dispatch<React.SetStateAction<number | null>>;
  saveCount: number;
  setSaveCount: React.Dispatch<React.SetStateAction<number>>;
}

export default function WuCauseOfDeathDataDialog(props: WuCauseOfDeathDataDialogProps) {
  const apiCall = useApiCall();
  const causeOfDeathDataInit: DropdownTO = {
    id: -1,
    name: '',
  };
  const [causeOfDeathData, setCauseOfDeathData] = useState<DropdownTO>(
    props.editData ? props.editData : causeOfDeathDataInit
  );

  React.useEffect(() => {
    if (props.editData) {
      setCauseOfDeathData(props.editData);
    }
  }, [props.editData]);

  const handleCancel = () => {
    handleClose();
  };

  const handleSave = () => {
    apiCall.post(LinkService.causeOfDeathPost(), causeOfDeathData, 'Speichern erfolgreich.').then((data) => {
      handleClose();
      props.setSaveCount(props.saveCount + 1);
    });
  };

  const handleClose = () => {
    setCauseOfDeathData(causeOfDeathDataInit);
    props.setEditRow(null);
  };

  const title = causeOfDeathData.id === -1 ? 'Neue Todesursache hinzufügen' : 'Todesursache bearbeiten';

  return (
    <Dialog open={props.openDialog}>
      <DialogTitle>{title}</DialogTitle>
      <DialogContent>
        <TextField
          autoFocus
          margin='dense'
          id='name'
          label='Todesursache'
          fullWidth
          variant='standard'
          value={causeOfDeathData.name}
          onChange={(e) => setCauseOfDeathData({ ...causeOfDeathData, name: e.target.value })}
        />{' '}
      </DialogContent>
      <DialogActions>
        <Button onClick={handleCancel}>Abbrechen</Button>
        <Button onClick={handleSave}>Speichern</Button>
      </DialogActions>
    </Dialog>
  );
}
