import * as React from 'react';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { useRef, useState } from 'react';
import CalenderPrizeTO from './CalenderPrizeTO';
import useApiCall from '../system/useApiCall';
import LinkService from '../system/LinkService';
import { useSnackbar } from 'notistack';
import { Checkbox, FormControlLabel, FormGroup } from '@mui/material';

interface CalenderPrizeDialogProps {
  editData?: CalenderPrizeTO;
  openDialog: boolean;
  setEditRow: React.Dispatch<React.SetStateAction<number | null>>;
  saveCount: number;
  setSaveCount: React.Dispatch<React.SetStateAction<number>>;
}

export default function CalenderPrizeDialog(props: CalenderPrizeDialogProps) {
  const apiCall = useApiCall();
  const calenderPrizeDataInit: CalenderPrizeTO = {
    id: -1,
    description: '',
    communityPrize: false,
  };
  const [calenderPrizeData, setCalenderPrizeData] = useState<CalenderPrizeTO>(
    props.editData ? props.editData : calenderPrizeDataInit
  );

  React.useEffect(() => {
    if (props.editData) {
      setCalenderPrizeData(props.editData);
    }
  }, [props.editData]);

  const handleCancel = () => {
    handleClose();
  };

  const handleSave = () => {
    apiCall.post(LinkService.calenderPrizePost(), calenderPrizeData, 'Speichern erfolgreich.').then((data) => {
      handleClose();
      props.setSaveCount(props.saveCount + 1);
    });
  };

  const handleClose = () => {
    setCalenderPrizeData(calenderPrizeDataInit);
    props.setEditRow(null);
  };

  const title = calenderPrizeData.id === -1 ? 'Neuen Preis hinzufügen' : 'Preis bearbeiten';

  return (
    <Dialog open={props.openDialog}>
      <DialogTitle>{title}</DialogTitle>
      <DialogContent>
        <TextField
          autoFocus
          margin='dense'
          id='name'
          label='Name'
          fullWidth
          variant='standard'
          value={calenderPrizeData.description}
          onChange={(e) => setCalenderPrizeData({ ...calenderPrizeData, description: e.target.value })}
        />{' '}
        <FormGroup>
          <FormControlLabel
            control={
              <Checkbox
                checked={calenderPrizeData.communityPrize}
                onChange={(e) => setCalenderPrizeData({ ...calenderPrizeData, communityPrize: e.target.checked })}
              />
            }
            label='Comhuhnity Preis'
          />
        </FormGroup>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleCancel}>Abbrechen</Button>
        <Button onClick={handleSave}>Speichern</Button>
      </DialogActions>
    </Dialog>
  );
}
