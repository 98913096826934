import React, { ReactNode, useEffect, useState } from 'react';
import LinkService from '../../system/LinkService';
import useApiCall from '../../system/useApiCall';
import StandardTable from '../../common/Component/StandardTable';
import { GridColumns, GridRenderCellParams, GridRowModel, GridRowsProp } from '@mui/x-data-grid';
import WuParticipantModTO from '../TO/WuParticipantModTO';
import WuParticipantDataDialog from './WuParticipantDataDialog';
import DropdownTO from '../../common/TO/DropdownTO';
import { Checkbox, MenuItem, Select, Typography } from '@mui/material';
import { useWuEventDropdownData } from '../Helper/WuHelperHooks';

export default function WuParticipantPage() {
  const [editRow, setEditRow] = useState<number | null>(null);
  const [openDataDialog, setOpenDataDialog] = useState<boolean>(false);
  const [saveCount, setSaveCount] = useState<number>(0);
  const [wuParticipantList, setWuParticipantList] = useState<WuParticipantModTO[]>([]);
  const [selectedWuEvent, setSelectedWuEvent] = useState<number>(-1);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const apiCall = useApiCall();
  const wuEventDropdownData = useWuEventDropdownData();

  useEffect(() => {
    if (wuEventDropdownData.length > 0) {
      getWuEventActiveId();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [wuEventDropdownData.length]);

  useEffect(() => {
    getWuParticipantAll();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [saveCount, selectedWuEvent]);

  useEffect(() => {
    if (editRow === null && openDataDialog) {
      setOpenDataDialog(false);
    } else if (editRow !== null && !openDataDialog) {
      setOpenDataDialog(true);
    }
  }, [editRow, openDataDialog]);

  const getWuParticipantAll = () => {
    if (selectedWuEvent > -1) {
      setIsLoading(true);
      apiCall.get(LinkService.wuParticipantsByWuEventId(selectedWuEvent)).then((data) => {
        if (data) {
          const list: WuParticipantModTO[] = data;
          setWuParticipantList(list);
          setIsLoading(false);
        }
      });
    }
  };

  const getWuEventActiveId = () => {
    apiCall.get(LinkService.wuEventActiveId()).then((data) => {
      if (data) {
        const eventId: number = data;
        setSelectedWuEvent(eventId);
      }
    });
  };

  const deleteWuParticipantData = (id: number) => {
    apiCall.delete(LinkService.wuParticipantDelete(id), 'Datensatz gelöscht.').then((data) => {
      getWuParticipantAll();
    });
  };

  const getEditData = () => {
    const wuParticipantData = wuParticipantList.find((item: WuParticipantModTO) => item.id === editRow);
    return wuParticipantData;
  };

  const getEditDataById = (id: number) => {
    const wuParticipantData = wuParticipantList.find((item: WuParticipantModTO) => item.id === id);
    return wuParticipantData;
  };

  const updateDataFromUpdateWithinTable = (data: GridRowModel) => {
    const index = wuParticipantList.findIndex((item: WuParticipantModTO) => item.id === data.id);
    if (index >= 0) {
      wuParticipantList[index] = { ...data };
    }
  };

  const renderSpawned = (params: GridRenderCellParams<any, any, any>): ReactNode => {
    return (
      <Checkbox
        id='isSpawned'
        checked={params.value}
        onChange={(e) => {
          const wuParticipantData = getEditDataById(params.row.id);
          wuParticipantData!.spawned = e.target.checked;
          apiCall.post(LinkService.wuParticipantPost(), wuParticipantData, 'Speichern erfolgreich.').then((data) => {
            setSaveCount(saveCount + 1);
          });
        }}
      />
    );
  };

  const renderContacted = (params: GridRenderCellParams<any, any, any>): ReactNode => {
    return (
      <Checkbox
        id='isContacted'
        checked={params.value}
        onChange={(e) => {
          const wuParticipantData = getEditDataById(params.row.id);
          wuParticipantData!.contacted = e.target.checked;
          apiCall.post(LinkService.wuParticipantPost(), wuParticipantData, 'Speichern erfolgreich.').then((data) => {
            setSaveCount(saveCount + 1);
          });
        }}
      />
    );
  };

  const columns: GridColumns = [
    { field: 'id', headerName: 'Nr.', type: 'number', width: 80, editable: false },
    { field: 'twitchname', headerName: 'Twitch-Name', flex: 1, editable: true },
    { field: 'galleryname', headerName: 'Galeriename', flex: 1, editable: true },
    { field: 'simCreatedBy', headerName: 'Sim-Ersteller', flex: 1, editable: true },
    { field: 'typeOfRegistration', headerName: 'Art der Reg.', flex: 1, editable: true },
    { field: 'household', headerName: 'Haushalt', flex: 1, editable: true },
    { field: 'nameOfSim', headerName: 'Sim-Name', flex: 1, editable: true },
    { field: 'team', headerName: 'Team', flex: 1, editable: true },
    { field: 'comment', headerName: 'Kommentar', flex: 1, editable: true },
    {
      field: 'wildcard',
      headerName: 'Wildcard',
      type: 'boolean',
      flex: 1,
      editable: true,
      // renderCell: renderCheckbox,
    },
    { field: 'spawned', headerName: 'Spawned', flex: 1, editable: false, renderCell: renderSpawned },
    { field: 'contacted', headerName: 'Angeschrieben', flex: 1, editable: false, renderCell: renderContacted },
  ];

  const rows: GridRowsProp = [...wuParticipantList];

  return (
    <div>
      <Select
        margin='dense'
        id='wuevent'
        label='WÜ Event'
        variant='standard'
        value={selectedWuEvent}
        onChange={(e) => setSelectedWuEvent(e.target.value as number)}
      >
        <MenuItem key={'wuDropdown_please_choose'} value={-1}>
          Event auswählen
        </MenuItem>
        {wuEventDropdownData.map((dropdownItem: DropdownTO) => (
          <MenuItem key={'wuDropdown_' + dropdownItem.id} value={dropdownItem.id}>
            {dropdownItem.name}
          </MenuItem>
        ))}
      </Select>
      <WuParticipantDataDialog
        openDialog={openDataDialog}
        setEditRow={setEditRow}
        editData={editRow ? getEditData() : undefined}
        saveCount={saveCount}
        setSaveCount={setSaveCount}
        wuDropdown={wuEventDropdownData}
        preselectedWuEvent={selectedWuEvent}
      />
      <Typography component='p' variant='caption'>
        Alle Sims, bei denen "spawned" angehakt ist, werden im Bingo berücksichtigt.
      </Typography>
      <StandardTable
        saveURL={LinkService.wuParticipantPost()}
        editRowNumber={setEditRow}
        columns={columns}
        rows={rows}
        deleteFunction={deleteWuParticipantData}
        isLoading={isLoading}
        updateDataSource={updateDataFromUpdateWithinTable}
      />
    </div>
  );
}
