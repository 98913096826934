import React, { useEffect, useState } from 'react';
import LinkService from '../../system/LinkService';
import useApiCall from '../../system/useApiCall';
import StandardTable from '../../common/Component/StandardTable';
import { GridColumns, GridRowsProp } from '@mui/x-data-grid';
import DropdownTO from '../../common/TO/DropdownTO';
import WuCauseOfDeathDataDialog from './WuCauseOfDeathDataDialog';

export default function WuCauseOfDeathPage() {
  const [editRow, setEditRow] = useState<number | null>(null);
  const [openDataDialog, setOpenDataDialog] = useState<boolean>(false);
  const [saveCount, setSaveCount] = useState<number>(0);
  const [causeOfDeathList, setCauseOfDeathList] = useState<DropdownTO[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const apiCall = useApiCall();

  useEffect(() => {
    getCauseOfDeathAll();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [saveCount]);

  useEffect(() => {
    if (editRow === null && openDataDialog) {
      setOpenDataDialog(false);
    } else if (editRow !== null && !openDataDialog) {
      setOpenDataDialog(true);
    }
  }, [editRow, openDataDialog]);

  const getCauseOfDeathAll = () => {
    setIsLoading(true);
    apiCall.get(LinkService.dropdownCauseOfDeath()).then((data) => {
      if (data) {
        const list: DropdownTO[] = data;
        setCauseOfDeathList(list);
        setIsLoading(false);
      }
    });
  };

  const deleteCauseOfDeathData = (id: number) => {
    apiCall.delete(LinkService.causeOfDeathDelete(id), 'Datensatz gelöscht.').then((data) => {
      getCauseOfDeathAll();
    });
  };

  const getEditData = () => {
    const wuParticipantData = causeOfDeathList.find((item: DropdownTO) => item.id === editRow);
    return wuParticipantData;
  };

  const columns: GridColumns = [
    { field: 'id', headerName: 'ID', type: 'number', width: 40, editable: false },
    { field: 'name', headerName: 'Todesursache', flex: 1, editable: true },
  ];

  const rows: GridRowsProp = [...causeOfDeathList];

  return (
    <div>
      <WuCauseOfDeathDataDialog
        openDialog={openDataDialog}
        setEditRow={setEditRow}
        editData={editRow ? getEditData() : undefined}
        saveCount={saveCount}
        setSaveCount={setSaveCount}
      />
      <StandardTable
        saveURL={''}
        editRowNumber={setEditRow}
        columns={columns}
        rows={rows}
        deleteFunction={deleteCauseOfDeathData}
        isLoading={isLoading}
      />
    </div>
  );
}
