import { CircularProgress, MenuItem, Select, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import DropdownTO from '../../common/TO/DropdownTO';
import LinkService from '../../system/LinkService';
import useApiCall from '../../system/useApiCall';
import { useWuEventActiveId, useWuEventDropdownData } from '../Helper/WuHelperHooks';
import WuBingoUserRequestTO from '../TO/WuBingoUserRequestTO';

export default function WuBingoListPage() {
  const [selectedWuEvent, setSelectedWuEvent] = useState<number>(-1);
  const [bingoList, setBingoList] = useState<WuBingoUserRequestTO[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const apiCall = useApiCall();
  const wuEventDropdownData = useWuEventDropdownData();
  const wuEventActiveId = useWuEventActiveId();

  useEffect(() => {
    setSelectedWuEvent(wuEventActiveId);
  }, [wuEventActiveId]);

  useEffect(() => {
    getBingoList(selectedWuEvent);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedWuEvent]);

  const getBingoList = (wuEventId: number) => {
    setIsLoading(true);
    apiCall.get(LinkService.wuBingoByWuEventIdList(wuEventId)).then((data) => {
      if (data) {
        const list: WuBingoUserRequestTO[] = data;
        setBingoList(list);
        setIsLoading(false);
      }
    });
  };

  return (
    <div>
      <Select
        margin='dense'
        id='wuevent'
        label='WÜ Event'
        variant='standard'
        value={selectedWuEvent}
        onChange={(e) => setSelectedWuEvent(e.target.value as number)}
      >
        <MenuItem key={'wuDropdown_please_choose'} value={-1}>
          Event auswählen
        </MenuItem>
        {wuEventDropdownData.map((dropdownItem: DropdownTO) => (
          <MenuItem key={'wuDropdown_' + dropdownItem.id} value={dropdownItem.id}>
            {dropdownItem.name}
          </MenuItem>
        ))}
      </Select>
      <Typography paragraph>
        {isLoading && (
          <span>
            <CircularProgress /> lädt...
          </span>
        )}
        {!isLoading &&
          bingoList.length > 0 &&
          bingoList.map((item: WuBingoUserRequestTO, index: number) => {
            return (
              <span>
                {index + 1}. {item.twitchuser} (
                {item.participants.map((sim, index: number) => {
                  return sim.nameOfSim + (index !== item.participants.length - 1 ? ', ' : '');
                })}
                )
                <br />
              </span>
            );
          })}
        {!isLoading && bingoList.length < 1 && <span>Bisher sind keine Bingos vorhanden.</span>}
      </Typography>
    </div>
  );
}
