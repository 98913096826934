import React, { useEffect, useState } from 'react';
import { TableCell, TableRow } from '@mui/material';
import SottGameRoundScoreTeamWrapperTO from '../TO/SottGameRoundScoreTeamWrapperTO';
import ScoreCellInput from './ScoreCellInput';
import SottGameRoundScoreTO from '../TO/SottGameRoundScoreTO';

interface ScoreTeamRowProps {
  teamData: SottGameRoundScoreTeamWrapperTO;
}

export default function ScoreTeamRow(props: ScoreTeamRowProps) {
  const [totalScore, setTotalScore] = useState<number | null>(0);

  const calculateTotalScore = () => {
    let calcTotalScore: number = 0;
    props.teamData.gameRoundScoreData.forEach((scoreData) => {
      calcTotalScore += scoreData.score ? scoreData.score : 0;
    });
    setTotalScore(calcTotalScore);
  };

  const updateTotalScore = (scoreData: SottGameRoundScoreTO) => {
    const updatedScore = props.teamData.gameRoundScoreData.find((item) => item.id === scoreData.id);
    if (updatedScore) {
      updatedScore.score = scoreData.score;
      calculateTotalScore();
    }
  };

  const [gameRounds] = useState(() => {
    const gameRoundList: JSX.Element[] = [];
    props.teamData.gameRoundScoreData.forEach((scoreData) => {
      gameRoundList.push(
        <TableCell key={'score_data_cell_' + scoreData.id}>
          <ScoreCellInput
            key={'score_data_input_' + scoreData.id}
            scoreData={scoreData}
            updateTotalScore={updateTotalScore}
          />
        </TableCell>
      );
    });
    return gameRoundList;
  });

  useEffect(() => {
    calculateTotalScore();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [gameRounds.length]);

  return (
    <TableRow key={'teamRow_' + props.teamData.teamName}>
      <TableCell>
        <i>{props.teamData.teamName}</i>
      </TableCell>
      {gameRounds}
      <TableCell>
        <b>{totalScore}</b>
      </TableCell>
    </TableRow>
  );
}
