import React, { useEffect, useState } from 'react';
import LinkService from '../system/LinkService';
import useApiCall from '../system/useApiCall';
import StandardTable from '../common/Component/StandardTable';
import { GridColumns, GridRowsProp } from '@mui/x-data-grid';
import SupportDefinitionTO from './SupportDefinitionTO';
import Typography from '@mui/material/Typography/Typography';

export default function SupportDefinitionPage() {
  const [supportDefinitionList, setSupportDefinitionList] = useState<SupportDefinitionTO[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const apiCall = useApiCall();

  useEffect(() => {
    getCommandAll();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getCommandAll = () => {
    setIsLoading(true);
    apiCall.get(LinkService.getSupportDefinitionAll()).then((data) => {
      if (data) {
        const list: SupportDefinitionTO[] = data;
        setSupportDefinitionList(list);
        setIsLoading(false);
      }
    });
  };

  const columns: GridColumns = [
    { field: 'id', headerName: 'ID', type: 'number', width: 30, editable: false },
    { field: 'hidden', headerName: 'hidden (editierbar)', type: 'boolean', flex: 1, editable: true },
    { field: 'platform', headerName: 'Plattform', flex: 1, editable: false },
    { field: 'type', headerName: 'Typ', flex: 1, editable: false },
    {
      field: 'subathonSecPerAmount',
      headerName: 'Subathon Sekunden (editierbar)',
      type: 'number',
      flex: 2,
      editable: true,
    },
    { field: 'respectingAlerts', headerName: 'beachtet Alerts (editierbar)', type: 'boolean', flex: 2, editable: true },
    {
      field: 'triggeringChatMessage',
      headerName: 'löst Chat-Nachricht aus (editierbar)',
      type: 'boolean',
      flex: 2,
      editable: true,
    },
  ];

  const rows: GridRowsProp = [...supportDefinitionList];

  return (
    <div>
      <Typography component='p' variant='caption'>
        Werte mit Doppelklick editieren.
      </Typography>
      <StandardTable
        saveURL={LinkService.postSupportDefinition()}
        columns={columns}
        rows={rows}
        isLoading={isLoading}
        hideActions
        hideToolbar
      />
    </div>
  );
}
