import React, { useEffect, useState } from 'react';
import LinkService from '../system/LinkService';
import useApiCall from '../system/useApiCall';
import StandardTable from '../common/Component/StandardTable';
import { GridColumns, GridRowsProp } from '@mui/x-data-grid';
import AvatarControlTO from './AvatarControlTO';
import Typography from '@mui/material/Typography/Typography';

export default function AvatarControlPage() {
  const [controlList, setControlList] = useState<AvatarControlTO[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const apiCall = useApiCall();

  useEffect(() => {
    getAvatarAll();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getAvatarAll = () => {
    setIsLoading(true);
    apiCall.get(LinkService.getAvatarControlAll()).then((data) => {
      if (data) {
        const list: AvatarControlTO[] = data;
        let idCount = 1;
        for (const item of list) {
          item.id = idCount;
          idCount++;
        }
        setControlList(list);
        setIsLoading(false);
      }
    });
  };

  const columns: GridColumns = [
    { field: 'key', headerName: 'Control Name', flex: 3, editable: false },
    { field: 'valueFormatted', headerName: 'Wert (editierbar)', flex: 2, editable: true },
    { field: 'valueType', headerName: 'Typ', flex: 2, editable: false },
    { field: 'description', headerName: 'Beschreibung (editierbar)', flex: 9, editable: true },
  ];

  const rows: GridRowsProp = [...controlList];

  return (
    <div>
      <Typography component='p' variant='caption'>
        Werte mit Doppelklick editieren.
      </Typography>
      <StandardTable
        saveURL={LinkService.postAvatarControl()}
        columns={columns}
        rows={rows}
        isLoading={isLoading}
        hideActions
        hideToolbar
      />
    </div>
  );
}
