import React, { useEffect, useState } from 'react';
import LinkService from '../../system/LinkService';
import useApiCall from '../../system/useApiCall';
import DropdownTO from '../../common/TO/DropdownTO';
import {
  Button,
  FormControl,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import { useSottEventDropdownData } from '../Helper/SottHelperHooks';
import Grid from '@mui/material/Grid';
import ConfirmationDialog from '../../common/Component/ConfirmationDialog';
import SottGameRoundScoreTO from '../TO/SottGameRoundScoreTO';
import SottGameRoundScoreGameWrapperTO from '../TO/SottGameRoundScoreGameWrapperTO';
import SottGameRoundScoreTeamWrapperTO from '../TO/SottGameRoundScoreTeamWrapperTO';
import ScoreTeamRow from './ScoreTeamRow';

export default function SottScorePage() {
  const [openCreateGameplanDialog, setOpenCreateGameplanDialog] = useState<boolean>(false);
  const [gameRoundScoreData, setGameRoundScoreData] = useState<SottGameRoundScoreGameWrapperTO[]>([]);
  const [selectedSottEvent, setSelectedSottEvent] = useState<number>(-1);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isLoadingCreateGameplan, setIsLoadingCreateGameplan] = useState<boolean>(false);
  const apiCall = useApiCall();
  const SottEventDropdownData = useSottEventDropdownData();

  useEffect(() => {
    if (SottEventDropdownData.length > 0) {
      getSottEventActiveId();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [SottEventDropdownData.length]);

  useEffect(() => {
    getGameRondScoreData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedSottEvent]);

  const getSottEventActiveId = () => {
    apiCall.get(LinkService.sottEventActiveId()).then((data) => {
      if (data) {
        const eventId: number = data;
        setSelectedSottEvent(eventId);
      }
    });
  };

  const getGameRondScoreData = () => {
    if (selectedSottEvent > -1) {
      setIsLoading(true);
      apiCall.get(LinkService.sottGameRoundScoreByEventId(selectedSottEvent)).then((data: SottGameRoundScoreTO[]) => {
        if (data) {
          prepareScoreData(data);
          setIsLoading(false);
        }
      });
    }
  };

  const prepareScoreData = (data: SottGameRoundScoreTO[]) => {
    const allGames: SottGameRoundScoreGameWrapperTO[] = [];

    data.forEach((item, index) => {
      const gameIndex: number = allGames.findIndex((roundScore) => roundScore.gameId === item.sottGame);
      if (gameIndex < 0) {
        // not in allGames yet

        // create first entry for team
        const newTeamEntry: SottGameRoundScoreTeamWrapperTO = {
          teamId: item.sottTeam,
          teamName: item.teamname,
          gameRoundScoreData: [item],
        };

        const newGameEntry: SottGameRoundScoreGameWrapperTO = {
          gameId: item.sottGame,
          gameName: item.gamename,
          teams: [newTeamEntry],
        };
        allGames.push(newGameEntry);
      } else {
        // game already in list
        // get game
        const game = allGames[gameIndex];
        // check if team already exists in list
        const teamIndex: number = game.teams.findIndex((team) => team.teamId === item.sottTeam);
        if (teamIndex < 0) {
          // not in list yet

          // create first entry for team
          const newTeamEntry: SottGameRoundScoreTeamWrapperTO = {
            teamId: item.sottTeam,
            teamName: item.teamname,
            gameRoundScoreData: [item],
          };
          game.teams.push(newTeamEntry);
        } else {
          // team already in list
          game.teams[teamIndex].gameRoundScoreData.push(item);
        }
      }
    });
    setGameRoundScoreData(allGames);
  };

  const createNewGamePlan = () => {
    if (selectedSottEvent > -1) {
      setIsLoadingCreateGameplan(true);
      apiCall
        .get(LinkService.sottCreateGameRoundScoreByEventId(selectedSottEvent), 'Spielplan erstellt.')
        .then((data) => {
          if (data) {
            setOpenCreateGameplanDialog(false);
            setIsLoadingCreateGameplan(false);
          }
        })
        .catch(() => {
          setIsLoadingCreateGameplan(false);
        });
    }
  };

  return (
    <div>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <FormControl>
            <InputLabel id='wuevent-label'>SooT Event</InputLabel>
            <Select
              margin='dense'
              id='sottevent'
              label='SotT Event'
              variant='standard'
              value={selectedSottEvent}
              onChange={(e) => setSelectedSottEvent(e.target.value as number)}
            >
              <MenuItem key={'sottDropdown_please_choose'} value={-1}>
                Event auswählen
              </MenuItem>
              {SottEventDropdownData.map((dropdownItem: DropdownTO) => (
                <MenuItem key={'sottDropdown_' + dropdownItem.id} value={dropdownItem.id}>
                  {dropdownItem.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={12}>
          <Button variant='outlined' onClick={() => setOpenCreateGameplanDialog(true)} disabled={selectedSottEvent < 0}>
            Erzeuge Spielplan
          </Button>
          {/* <Button variant='outlined' onClick={() => getGameRondScoreData()} disabled={selectedSottEvent < 0}>
            Lade Spielplan
          </Button> */}
        </Grid>

        {gameRoundScoreData &&
          gameRoundScoreData.length > 0 &&
          gameRoundScoreData.map((gameData: SottGameRoundScoreGameWrapperTO) => {
            const numberOfRounds = gameData?.teams[0]?.gameRoundScoreData?.length ?? 0;
            const cellHeader: JSX.Element[] = [];
            for (let i = 1; i <= numberOfRounds; i++) {
              cellHeader.push(<TableCell key={'header_' + gameData.gameId + '_' + i}>Runde {i} </TableCell>);
            }
            const teamRow: SottGameRoundScoreTeamWrapperTO[] = gameData.teams;

            return (
              <Grid item xs={12} key={'SotT_' + gameData.gameId}>
                <Paper elevation={2} key={'paper_' + gameData.gameId}>
                  <TableContainer sx={{ maxHeight: 440 }}>
                    <Table stickyHeader aria-label='sticky table' size='small'>
                      <TableHead>
                        <TableRow>
                          <TableCell>
                            <b>{gameData.gameName}</b>
                          </TableCell>
                          {cellHeader}
                          <TableCell>
                            <b>Gesamt</b>
                          </TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {teamRow.map((teamData) => {
                          return <ScoreTeamRow key={'TeamRow_' + teamData.teamId} teamData={teamData}></ScoreTeamRow>;
                        })}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </Paper>
              </Grid>
            );
          })}
      </Grid>
      {(!gameRoundScoreData || gameRoundScoreData.length === 0) && (
        <Typography>
          Es ist noch kein Spielplan vorhanden. Um einen Spielplan anzulegen, klicke auf <i>ERZEUGE SPIELPLAN</i>
        </Typography>
      )}
      <ConfirmationDialog
        title='ACHTUNG! Willst du das wirklich?'
        text='Wenn du diese Aktion bestätigst, wird der bisherige Spielplan gelöscht und ist unwiederbringlich verloren!'
        openDialog={openCreateGameplanDialog}
        onCancle={() => {
          setOpenCreateGameplanDialog(false);
        }}
        onConfirm={createNewGamePlan}
        isLoading={isLoadingCreateGameplan}
      />
    </div>
  );
}
