import React, { ReactNode, useEffect, useState } from 'react';
import LinkService from '../../system/LinkService';
import useApiCall from '../../system/useApiCall';
import StandardTable from '../../common/Component/StandardTable';
import { GridColumns, GridRenderCellParams, GridRowModel, GridRowsProp } from '@mui/x-data-grid';
import SottEventDataDialog from './SottEventDataDialog';
import SottEventTO from '../TO/SottEventTO';
import { Checkbox } from '@mui/material';
import TableUtil from '../../system/TableUtil';

export default function SottEventPage() {
  const [editRow, setEditRow] = useState<number | null>(null);
  const [openDataDialog, setOpenDataDialog] = useState<boolean>(false);
  const [saveCount, setSaveCount] = useState<number>(0);
  const [sottEventList, setSottEventList] = useState<SottEventTO[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const apiCall = useApiCall();

  useEffect(() => {
    getSottEventAll();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [saveCount]);

  useEffect(() => {
    if (editRow === null && openDataDialog) {
      setOpenDataDialog(false);
    } else if (editRow !== null && !openDataDialog) {
      setOpenDataDialog(true);
    }
  }, [editRow, openDataDialog]);

  const getSottEventAll = () => {
    setIsLoading(true);
    apiCall.get(LinkService.sottEventAll()).then((data) => {
      if (data) {
        const list: SottEventTO[] = data;
        setSottEventList(list);
        setIsLoading(false);
      }
    });
  };

  const deleteSottEventData = (id: number) => {
    apiCall.delete(LinkService.sottEventDelete(id), 'Datensatz gelöscht.').then((data) => {
      getSottEventAll();
    });
  };

  const getEditData = () => {
    const sottEventData = sottEventList.find((item: SottEventTO) => item.id === editRow);
    return sottEventData;
  };

  const getEditDataById = (id: number) => {
    const sottEventData = sottEventList.find((item: SottEventTO) => item.id === id);
    return sottEventData;
  };

  const updateDataFromUpdateWithinTable = (data: GridRowModel) => {
    const index = sottEventList.findIndex((item: SottEventTO) => item.id === data.id);
    if (index >= 0) {
      sottEventList[index] = { ...data };
    }
  };

  const renderSottEventActive = (params: GridRenderCellParams<any, any, any>): ReactNode => {
    return (
      <Checkbox
        id='isActive'
        checked={params.value}
        onChange={(e) => {
          const sottEventData = getEditDataById(params.row.id);
          sottEventData!.active = e.target.checked;
          apiCall.post(LinkService.sottEventPost(), sottEventData, 'Speichern erfolgreich.').then((data) => {
            setSaveCount(saveCount + 1);
          });
        }}
      />
    );
  };

  const columns: GridColumns = [
    { field: 'id', headerName: 'ID', type: 'number', width: 40, editable: false },
    { field: 'name', headerName: 'Name', flex: 1, editable: true },
    {
      field: 'eventDate',
      headerName: 'Event-Start',
      flex: 1,
      editable: false,
      valueFormatter: TableUtil.formatDateCell,
    },
    { field: 'active', headerName: 'Aktiv', flex: 1, editable: false, renderCell: renderSottEventActive },
  ];

  const rows: GridRowsProp = [...sottEventList];

  return (
    <div>
      <SottEventDataDialog
        openDialog={openDataDialog}
        setEditRow={setEditRow}
        editData={editRow ? getEditData() : undefined}
        saveCount={saveCount}
        setSaveCount={setSaveCount}
      />
      <StandardTable
        saveURL={LinkService.sottEventPost()}
        editRowNumber={setEditRow}
        columns={columns}
        rows={rows}
        deleteFunction={deleteSottEventData}
        isLoading={isLoading}
        updateDataSource={updateDataFromUpdateWithinTable}
      />
    </div>
  );
}
