import { TextField, TextFieldProps } from '@mui/material';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker/DateTimePicker';

interface DateAndTimePickerProps {
  label: string;
  value: Date | null;
  onChange: (date: Date | null, keyboardInputValue?: string | undefined) => void;
}

export default function DateAndTimePicker(props: DateAndTimePickerProps) {
  return (
    <DateTimePicker
      label={props.label}
      value={props.value}
      inputFormat='dd.MM.yyyy HH:mm'
      mask='__.__.____ __:__'
      ampm={false}
      onChange={props.onChange}
      renderInput={(params: TextFieldProps) => <TextField {...params} />}
    />
  );
}
