import axios from 'axios';
import { createRefresh } from 'react-auth-kit';
import LinkService from '../system/LinkService';

const refreshApi = createRefresh({
  interval: 10, // Refreshs the token in every 10 minutes
  refreshApiCallback: ({ authToken, authTokenExpireAt, refreshToken, refreshTokenExpiresAt, authUserState }) => {
    return axios
      .post(LinkService.authRefreshToken(), {
        refreshToken: refreshToken,
        oldAuthToken: authToken,
      })
      .then(({ data }) => {
        return {
          isSuccess: true,
          newAuthToken: data.token,
          newAuthTokenExpireIn: data.expiresInMinutes,
          newRefreshToken: data.refreshToken,
          newRefreshTokenExpiresIn: data.refreshTokenExpiresInMinutes,
          // You can also add new refresh token ad new user state
        };
      })
      .catch((e) => {
        console.error(e);
        return {
          isSuccess: false,
          newAuthToken: '',
        };
      });
  },
});

export default refreshApi;
