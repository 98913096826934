import { useEffect, useState } from 'react';
import DropdownTO from '../../common/TO/DropdownTO';
import LinkService from '../../system/LinkService';
import useApiCall from '../../system/useApiCall';

export function useWuEventDropdownData() {
  const [data, setData] = useState<DropdownTO[]>([]);
  const apiCall = useApiCall();

  useEffect(() => {
    apiCall.get(LinkService.dropdownWuEvents()).then((resultData) => {
      if (resultData) {
        const list: DropdownTO[] = resultData;
        setData(list);
      }
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return data;
}

export function useWuEventActiveId() {
  const [data, setData] = useState<number>(-1);
  const apiCall = useApiCall();

  useEffect(() => {
    apiCall.get(LinkService.wuEventActiveId()).then((data) => {
      if (data) {
        const eventId: number = data;
        setData(eventId);
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return data;
}
